import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, Map, List } from "immutable"
import _get from "lodash/get"
import _isNil from "lodash/isNil"
import _isFunction from "lodash/isFunction"

// ui elements
import ListItem, {
  DataProcessed,
  Author,
  LastRun,
  ItemName,
  PartOfDawg,
  ItemStatus,
  Schedules,
  Row,
  Tags,
  DiskUsage
} from "components/UI/DawgWsShared/ListItem"

// helpers, routes
import { getRoutePath } from "routes"

import "./WorkspaceListItem.css"

class WorkspaceListItem extends PureComponent {
  componentDidMount() {
    const { onInit } = this.props
    if (_isFunction(onInit)) {
      onInit()
    }
  }

  render() {
    const {
      authenticatedUser,
      job,
      workspace,
      selectFilterTag,
      invisible,
      innerRef,
      expandedSchedules,
      expandWorkspaceSchedules
    } = this.props
    const currentLayout = _get(authenticatedUser, "ui.workspacesListLayout", "one-col")
    const isCompact = currentLayout === "two-cols"

    const authorName = workspace.getIn(["author", "name"])
    const schedulesSetting = workspace.getIn(["settings", "schedules"])
    const scheduleExists = List.isList(schedulesSetting) && schedulesSetting.size > 0

    return (
      <ListItem
        key={workspace.id}
        isDisabled={workspace.disabled}
        isCompact={isCompact}
        isInvisible={invisible}
        innerRef={innerRef}
        job={job}
      >
        <Row>
          <ItemName
            route={{
              pathname: getRoutePath("workspace.show", { id: workspace.id }),
              state: {
                previous: true
              }
            }}
            name={workspace.name}
          />
          {!isCompact && <Author className="not-compact-author-name" authorName={authorName} />}
          {!isCompact && <PartOfDawg className="workspace-dawg" dawg={workspace.dawg} />}
          <ItemStatus className="workspace-status" job={job} />
        </Row>
        {isCompact && !_isNil(workspace.dawg) && (
          <Row>
            <PartOfDawg dawg={workspace.dawg} />
          </Row>
        )}
        <Row className="flex-row">
          {!isCompact && <DataProcessed job={job} />}
          {!isCompact && (
            <DiskUsage
              value={workspace.disk_usage}
              markDiskSpaceUsed={workspace.high_disk_usage === 1}
            />
          )}
          {!isCompact && (
            <LastRun datetime={workspace.last_activity} labelText="Configuration activity" />
          )}
          <LastRun datetime={workspace.last_run} labelText="Workspace activity" />
          {isCompact && <Author authorName={authorName} />}
        </Row>
        {!isCompact && scheduleExists && (
          <Row>
            <Schedules
              item={workspace}
              expandedSchedules={expandedSchedules}
              expandItemSchedules={expandWorkspaceSchedules}
            />
          </Row>
        )}
        <Tags tags={workspace.tags} selectFilterTag={selectFilterTag} />
      </ListItem>
    )
  }
}

WorkspaceListItem.defaultProps = {
  invisible: false
}

WorkspaceListItem.propTypes = {
  authenticatedUser: PropTypes.object,
  job: PropTypes.instanceOf(Record),
  workspace: PropTypes.instanceOf(Record).isRequired,
  invisible: PropTypes.bool,
  selectFilterTag: PropTypes.func.isRequired,
  expandedSchedules: PropTypes.instanceOf(Map).isRequired,
  expandWorkspaceSchedules: PropTypes.func.isRequired,
  onInit: PropTypes.func
}

export default React.forwardRef((props, ref) => <WorkspaceListItem innerRef={ref} {...props} />)
