import { createSelector } from "reselect"
import { Map, List } from "immutable"

import { initFuseSearch } from "helpers/table.helper"

const getActiveUsers = state => state.users.get("active")

const getDeletedUsers = state => state.users.get("removed")

export const getUsersData = createSelector([getActiveUsers], users => {
  if (users) {
    return Map({
      isFulfilled: true,
      data: users
    })
  } else {
    // initial state before fetching is null
    return Map({
      isFulfilled: false,
      data: Map()
    })
  }
})

export const getAllUsersMap = createSelector(
  [getActiveUsers, getDeletedUsers],
  (active, removed) => {
    if (active === null && removed === null) {
      return Map()
    } else if (active === null) {
      return removed
    } else if (removed === null) {
      return active
    } else {
      return active.merge(removed)
    }
  }
)

export const getUsersFuseData = createSelector([getActiveUsers], users => {
  if (users) {
    return Map({
      isFulfilled: true,
      data: initFuseSearch(List(users.toArray()), ["name"])
    })
  } else {
    return Map({
      isFulfilled: false,
      data: initFuseSearch(List(), ["name"])
    })
  }
})
