import axios from "axios"
import _get from "lodash/get"
import _isEqual from "lodash/isEqual"
import _isEmpty from "lodash/isEmpty"

import store from "store"
import { token } from "helpers/authenticatedUser.helper"
import { logout } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"
import { history } from "components/App"
import { getRoutePath } from "routes"

export const BASE_API_URL = `${window.location.origin}/api`

const DISCONNECTED_MESSAGE =
  "There was a connection error. Either you are offline, or the request you are trying to perform is too large."
const SOMETHING_WENT_WRONG_MESSAGE =
  "There was an error. Please, let us know about it at help@meiro.io"

const ERR_404_PREVENT_REDIRECT = ["missing_file"]

axios.defaults.baseURL = BASE_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json"
}

const responseSuccessHandler = response => response.data || {}

const responseErrorHandler = (preventNotFoundRedirect = false) => error => {
  const response = _get(error, "response")
  if (response === undefined) {
    // network problem
    const previousMessage = _get(store.getState().toast, "message")
    if (previousMessage === DISCONNECTED_MESSAGE) {
      const previousTimestamp = _get(store.getState().toast, "timestamp")
      if (Date.now() - previousTimestamp > 6000) {
        store.dispatch(showToast(DISCONNECTED_MESSAGE, TOAST.TYPE.ERROR))
      }
    } else {
      store.dispatch(showToast(DISCONNECTED_MESSAGE, TOAST.TYPE.ERROR))
    }
  }
  const status = _get(error, "response.status")
  const errorType = _get(error, "response.data.error_type")
  if (status === 403) {
    if (_get(history, "location.pathname") !== "/not-authorized") {
      history.push(getRoutePath("not-authorized"))
    }
  } else if (
    status === 404 &&
    !preventNotFoundRedirect &&
    !ERR_404_PREVENT_REDIRECT.includes(errorType)
  ) {
    if (_get(history, "location.pathname") !== "/not-found") {
      history.push(getRoutePath("not-found"))
    }
  } else {
    let message = _get(error, "response.data.message", "")
    if (message === "Invalid token") {
      message = ""
    }
    if (message === "Internal Server Error") {
      message = SOMETHING_WENT_WRONG_MESSAGE
    }
    if (message) {
      const previousMessage = _get(store.getState().toast, "message")
      if (previousMessage === SOMETHING_WENT_WRONG_MESSAGE) {
        const previousTimestamp = _get(store.getState().toast, "timestamp")
        if (Date.now() - previousTimestamp > 6000) {
          store.dispatch(showToast(message, TOAST.TYPE.ERROR))
        }
      } else {
        store.dispatch(showToast(message, TOAST.TYPE.ERROR))
      }
    }
    if (status === 401) {
      store.dispatch(logout())
    }
  }

  throw error
}

const requestFactory = (
  method,
  url,
  body = {},
  originData = {},
  preventNotFoundRedirect = false,
  useToken = true,
  ownToken = ""
) => {
  const config = {
    headers: {}
  }

  if (useToken) {
    config.headers["x-access-token"] = ownToken || token()
  }

  config.method = method.toString().toLowerCase()
  if (Object.keys(body).length > 0) {
    if (config.method === "get") {
      config.params = body
    }
  }
  if (config.method === "post") {
    config.data = body
  } else if (config.method === "patch") {
    if ((!_isEmpty(body) || !_isEmpty(originData)) && _isEqual(body, originData)) {
      const errMessage = "Nothing has been changed."
      store.dispatch(showToast(errMessage, TOAST.TYPE.ERROR))
      throw Object.assign(new Error(errMessage), { type: "nothing_changed" })
    } else {
      config.data = body
    }
  }

  return axios(url, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler(preventNotFoundRedirect))
}

export function api() {
  return {
    user: {
      login(data) {
        return requestFactory("post", "/login", data, {}, true, false)
      },
      logout() {
        return requestFactory("delete", "/login")
      },
      oktaLogin(data) {
        return requestFactory("post", "/okta_login", data, {}, true, false)
      },
      refreshToken() {
        return requestFactory("patch", "/login", {
          token: token()
        })
      },
      me(token = "") {
        let args = ["get", "/me", {}, {}, false, true]
        if (token) {
          args = [...args, token]
        }
        return requestFactory(...args)
      },
      resetPassword(data) {
        return requestFactory("post", "/users/password_reset", data, {}, true)
      },
      setPassword(data) {
        return requestFactory("patch", "/users/password_reset", data)
      },
      sendInvitation(data) {
        return requestFactory("post", "/users/invitation", data)
      },
      resendInvitation(email) {
        return requestFactory("patch", "/users/invitation", { email })
      },
      getInvitationLink(userId) {
        return requestFactory("get", `/users/${userId}/invitation_link`)
      },
      retrieve(user_id) {
        return requestFactory("get", `/users/${user_id}`)
      },
      list(offset = 0, limit = 50, load_full_structure = 0) {
        return requestFactory("get", "/users", {
          limit,
          offset,
          load_full_structure
        })
      },
      create(data) {
        return requestFactory("post", "/users", data)
      },
      modify(id, data, originData = {}) {
        return requestFactory("patch", `/users/${id}`, data, originData)
      },
      delete(id) {
        return requestFactory("delete", `/users/${id}`)
      },
      restore(id) {
        return requestFactory("patch", `/users/trash/${id}`)
      },
      trash: {
        list(offset = 0, limit = 50, load_full_structure = 0) {
          return requestFactory("get", "/users/trash", {
            offset,
            limit,
            load_full_structure
          })
        }
      }
    },
    acl: {
      list(
        workspace_id,
        offset = 0,
        limit = 50,
        load_full_structure = 0,
        order_by = "id",
        order_dir = "DESC"
      ) {
        return requestFactory("get", `/acl/workspaces/${workspace_id}`, {
          offset,
          limit,
          load_full_structure,
          order_by,
          order_dir
        })
      },
      create(workspace_id, data, load_full_structure = 0) {
        return requestFactory(
          "post",
          `/acl/workspaces/${workspace_id}?load_full_structure=${load_full_structure}`,
          data
        )
      },
      retrieve(workspace_id, user_id, load_full_structure = 0) {
        return requestFactory("get", `/acl/workspaces/${workspace_id}/users/${user_id}`, {
          load_full_structure
        })
      },
      modify(workspace_id, user_id, data, load_full_structure = 0) {
        return requestFactory(
          "patch",
          `/acl/workspaces/${workspace_id}/users/${user_id}?load_full_structure=${load_full_structure}`,
          data
        )
      },
      delete(workspace_id, user_id) {
        return requestFactory("delete", `/acl/workspaces/${workspace_id}/users/${user_id}`)
      },
      dawg: {
        list(
          dawg_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory("get", `/acl/dawgs/${dawg_id}`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        create(dawg_id, data, load_full_structure = 0) {
          return requestFactory(
            "post",
            `/acl/dawgs/${dawg_id}?load_full_structure=${load_full_structure}`,
            data
          )
        },
        retrieve(dawg_id, user_id, load_full_structure = 0) {
          return requestFactory("get", `/acl/dawgs/${dawg_id}/users/${user_id}`, {
            load_full_structure
          })
        },
        modify(dawg_id, user_id, data, load_full_structure = 0) {
          return requestFactory(
            "patch",
            `/acl/dawgs/${dawg_id}/users/${user_id}?load_full_structure=${load_full_structure}`,
            data
          )
        },
        delete(dawg_id, user_id) {
          return requestFactory("delete", `/acl/dawgs/${dawg_id}/users/${user_id}`)
        }
      },
      user: {
        retrieve(user_id, offset = 0, limit = 50, load_full_structure = 0, include_deleted = 0) {
          return requestFactory("get", `/acl/users/${user_id}`, {
            offset,
            limit,
            load_full_structure,
            include_deleted
          })
        },
        dawg: {
          retrieve(user_id, offset = 0, limit = 50, load_full_structure = 0, include_deleted = 0) {
            return requestFactory("get", `/acl/dawgs/users/${user_id}`, {
              offset,
              limit,
              load_full_structure,
              include_deleted
            })
          }
        }
      }
    },
    workspace: {
      retrieve(workspace_id, load_full_structure = 1) {
        return requestFactory("get", `/workspaces/${workspace_id}`, {
          load_full_structure
        })
      },
      list(
        offset = 0,
        limit = 50,
        load_full_structure = 1,
        order_by = "id",
        order_dir = "ASC",
        name_filter = "",
        tag_ids = [],
        show_enabled = 1,
        show_disabled = 1
      ) {
        return requestFactory("get", "/workspaces", {
          limit,
          offset,
          load_full_structure,
          order_by,
          order_dir,
          name_filter,
          tag_ids,
          show_enabled,
          show_disabled
        })
      },
      create(data, load_full_structure = 1) {
        return requestFactory(
          "post",
          `/workspaces?load_full_structure=${load_full_structure}`,
          data
        )
      },
      modifyWorkspace(id, data, originData = {}, load_full_structure = 1) {
        return requestFactory(
          "patch",
          `/workspaces/${id}?load_full_structure=${load_full_structure}`,
          data,
          originData
        )
      },
      delete(id) {
        return requestFactory("delete", `/workspaces/${id}`)
      },
      clone(workspace_id, load_full_structure = 1) {
        return requestFactory(
          "post",
          `/workspaces/${workspace_id}?load_full_structure=${load_full_structure}`
        )
      },
      restore(id, load_full_structure = 1) {
        return requestFactory(
          "patch",
          `/workspaces/trash/${id}?load_full_structure=${load_full_structure}`
        )
      },
      listLastJobs(workspace_ids = [], load_full_structure = 0) {
        return requestFactory("get", "/workspaces/last_jobs", {
          workspace_ids,
          load_full_structure
        })
      },
      configuration: {
        listLastJobs(workspace_id, configuration_ids = [], load_full_structure = 0) {
          return requestFactory("get", `/workspaces/${workspace_id}/configurations/last_jobs`, {
            configuration_ids,
            load_full_structure,
            include_settings: 0
          })
        }
      },
      job: {
        run(workspace_id, load_full_structure = 0) {
          return requestFactory(
            "post",
            `/workspaces/${workspace_id}/jobs?load_full_structure=${load_full_structure}`
          )
        },
        list(
          workspace_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory("get", `/workspaces/${workspace_id}/jobs`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        retrieve(workspace_id, job_id, load_full_structure = 0) {
          return requestFactory("get", `/workspaces/${workspace_id}/jobs/${job_id}`, {
            load_full_structure
          })
        },
        cancel(workspace_id, job_id, load_full_structure) {
          return requestFactory(
            "delete",
            `/workspaces/${workspace_id}/jobs/${job_id}?load_full_structure=${load_full_structure}`
          )
        },
        history: {
          list(
            workspace_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory("get", `/workspaces/${workspace_id}/jobs/${job_id}/history`, {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir
            })
          },
          retrieve(workspace_id, job_id, history_id, load_full_structure) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/jobs/${job_id}/history/${history_id}`,
              {
                load_full_structure
              }
            )
          }
        },
        configurationJob: {
          list(
            workspace_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC",
            include_deleted_configuration_jobs = 0
          ) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/jobs/${job_id}/configuration_jobs`,
              {
                offset,
                limit,
                load_full_structure,
                order_dir,
                order_by,
                include_deleted_configuration_jobs,
                include_settings: 0
              }
            )
          }
        },
        log: {
          list(
            workspace_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory("get", `/workspaces/${workspace_id}/jobs/${job_id}/logs`, {
              offset,
              limit,
              load_full_structure,
              order_dir,
              order_by
            })
          }
        }
      },
      history: {
        list(
          workspace_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory("get", `/workspaces/${workspace_id}/history`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        retrieve(workspace_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "get",
            `/workspaces/${workspace_id}/history/${history_id}?load_full_structure=${load_full_structure}`
          )
        },
        revertTo(workspace_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "patch",
            `/workspaces/${workspace_id}/history/${history_id}?load_full_structure=${load_full_structure}`
          )
        }
      },
      configurationJob: {
        list(
          workspace_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC",
          include_deleted_configuration_jobs = 0
        ) {
          return requestFactory("get", `/workspaces/${workspace_id}/configuration_jobs`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir,
            include_deleted_configuration_jobs,
            include_settings: 0
          })
        }
      },
      tag: {
        list(
          workspace_id,
          offset = 0,
          limit = 20,
          order_by = "id",
          order_dir = "ASC",
          load_full_structure = 0
        ) {
          return requestFactory("get", `/workspaces/${workspace_id}/tags`, {
            offset,
            limit,
            order_by,
            order_dir,
            load_full_structure
          })
        },
        assign(workspace_id, data, load_full_structure = 1) {
          return requestFactory(
            "post",
            `/workspaces/${workspace_id}/tags?load_full_structure=${load_full_structure}`,
            data
          )
        },
        delete(workspace_id, tag_id, load_full_structure = 0) {
          return requestFactory(
            "delete",
            `/workspaces/${workspace_id}/tags/${tag_id}?load_full_structure=${load_full_structure}`
          )
        }
      },
      data: {
        delete(workspace_id) {
          return requestFactory("delete", `/workspaces/${workspace_id}/data`)
        }
      }
    },
    dawg: {
      retrieve(dawg_id, load_full_structure = 1) {
        return requestFactory("get", `/dawgs/${dawg_id}`, {
          load_full_structure
        })
      },
      list(
        offset = 0,
        limit = 50,
        load_full_structure = 1,
        order_by = "id",
        order_dir = "ASC",
        name_filter = "",
        tag_ids = [],
        show_enabled = 1,
        show_disabled = 1
      ) {
        return requestFactory("get", "/dawgs", {
          limit,
          offset,
          load_full_structure,
          order_by,
          order_dir,
          name_filter,
          tag_ids,
          show_enabled,
          show_disabled
        })
      },
      create(data, load_full_structure = 1) {
        return requestFactory("post", `/dawgs?load_full_structure=${load_full_structure}`, data)
      },
      listLastJobs(dawg_ids = [], load_full_structure = 0) {
        return requestFactory("get", "/dawgs/last_jobs", {
          dawg_ids,
          load_full_structure
        })
      },
      modify(id, data, originData = {}, load_full_structure = 1) {
        return requestFactory(
          "patch",
          `/dawgs/${id}?load_full_structure=${load_full_structure}`,
          data,
          originData
        )
      },
      delete(id) {
        return requestFactory("delete", `/dawgs/${id}`)
      },
      restore(id, load_full_structure = 1) {
        return requestFactory(
          "patch",
          `/dawgs/trash/${id}?load_full_structure=${load_full_structure}`
        )
      },
      workspace: {
        list(
          dawg_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "ASC"
        ) {
          return requestFactory("get", `/dawgs/${dawg_id}/workspaces`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        modifyBulk(dawg_id, data, load_full_structure = 0) {
          return requestFactory(
            "patch",
            `/dawgs/${dawg_id}/workspaces?load_full_structure=${load_full_structure}`,
            data
          )
        }
      },
      job: {
        run(dawg_id, load_full_structure = 0) {
          return requestFactory(
            "post",
            `/dawgs/${dawg_id}/jobs?load_full_structure=${load_full_structure}`
          )
        },
        list(
          dawg_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory("get", `/dawgs/${dawg_id}/jobs`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        retrieve(dawg_id, job_id, load_full_structure = 0) {
          return requestFactory("get", `/dawgs/${dawg_id}/jobs/${job_id}`, {
            load_full_structure
          })
        },
        cancel(dawg_id, job_id, load_full_structure) {
          return requestFactory(
            "delete",
            `/dawgs/${dawg_id}/jobs/${job_id}?load_full_structure=${load_full_structure}`
          )
        },
        history: {
          list(
            dawg_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory("get", `/dawgs/${dawg_id}/jobs/${job_id}/history`, {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir
            })
          },
          retrieve(dawg_id, job_id, history_id, load_full_structure) {
            return requestFactory("get", `/dawgs/${dawg_id}/jobs/${job_id}/history/${history_id}`, {
              load_full_structure
            })
          }
        },
        workspaceJob: {
          list(
            dawg_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC",
            include_deleted_workspace_jobs = 0
          ) {
            return requestFactory("get", `/dawgs/${dawg_id}/jobs/${job_id}/workspace_jobs`, {
              offset,
              limit,
              load_full_structure,
              order_dir,
              order_by,
              include_deleted_workspace_jobs
            })
          }
        },
        log: {
          list(
            dawg_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory("get", `/dawgs/${dawg_id}/jobs/${job_id}/logs`, {
              offset,
              limit,
              load_full_structure,
              order_dir,
              order_by
            })
          }
        }
      },
      history: {
        list(
          dawg_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory("get", `/dawgs/${dawg_id}/history`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir
          })
        },
        retrieve(dawg_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "get",
            `/dawgs/${dawg_id}/history/${history_id}?load_full_structure=${load_full_structure}`
          )
        },
        revertTo(dawg_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "patch",
            `/dawgs/${dawg_id}/history/${history_id}?load_full_structure=${load_full_structure}`
          )
        }
      },
      tag: {
        list(
          dawg_id,
          offset = 0,
          limit = 20,
          order_by = "id",
          order_dir = "ASC",
          load_full_structure = 0
        ) {
          return requestFactory("get", `/dawgs/${dawg_id}/tags`, {
            offset,
            limit,
            order_by,
            order_dir,
            load_full_structure
          })
        },
        assign(dawg_id, data, load_full_structure = 1) {
          return requestFactory(
            "post",
            `/dawgs/${dawg_id}/tags?load_full_structure=${load_full_structure}`,
            data
          )
        },
        delete(dawg_id, tag_id, load_full_structure = 0) {
          return requestFactory(
            "delete",
            `/dawgs/${dawg_id}/tags/${tag_id}?load_full_structure=${load_full_structure}`
          )
        }
      },
      workspaceJob: {
        list(
          dawgId,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC",
          include_deleted_workspace_jobs = 0
        ) {
          return requestFactory("get", `/dawgs/${dawgId}/workspace_jobs`, {
            offset,
            limit,
            load_full_structure,
            order_by,
            order_dir,
            include_deleted_workspace_jobs
          })
        }
      }
    },
    oauth: {
      list(offset = 0, limit = 50, load_full_structure = 0) {
        return requestFactory("get", "/oauth_repositories", {
          limit,
          offset,
          load_full_structure
        })
      },
      modify(id, data, previousData = {}) {
        return requestFactory("patch", `/oauth_repositories/${id}`, data, previousData)
      }
    },
    component: {
      list(offset = 0, limit = 50, load_full_structure = 0, show_hidden = 1) {
        return requestFactory("get", "/components", {
          limit,
          offset,
          load_full_structure,
          show_hidden
        })
      }
    },
    configuration: {
      list(workspace_id, offset = 0, limit = 50, load_full_structure = 0) {
        return requestFactory("get", `/workspaces/${workspace_id}/configurations`, {
          offset,
          limit,
          load_full_structure
        })
      },
      retrieve(workspace_id, configuration_id, load_full_structure = 0) {
        return requestFactory(
          "get",
          `/workspaces/${workspace_id}/configurations/${configuration_id}`,
          {
            load_full_structure
          }
        )
      },
      create(workspace_id, data, load_full_structure = 0) {
        return requestFactory(
          "post",
          `/workspaces/${workspace_id}/configurations?load_full_structure=${load_full_structure}`,
          data
        )
      },
      modifyBulk(workspace_id, data, load_full_structure = 0) {
        return requestFactory(
          "patch",
          `/workspaces/${workspace_id}/configurations?load_full_structure=${load_full_structure}`,
          data
        )
      },
      modify(workspace_id, configuration_id, data, originData = {}, load_full_structure = 0) {
        return requestFactory(
          "patch",
          `/workspaces/${workspace_id}/configurations/${configuration_id}?load_full_structure=${load_full_structure}`,
          data,
          originData
        )
      },
      clone(workspace_id, configuration_id, data, load_full_structure = 0) {
        return requestFactory(
          "post",
          `/workspaces/${workspace_id}/configurations/${configuration_id}?load_full_structure=${load_full_structure}`,
          data
        )
      },
      delete(workspace_id, configuration_id) {
        return requestFactory(
          "delete",
          `workspaces/${workspace_id}/configurations/${configuration_id}`
        )
      },
      restore(workspace_id, id, load_full_structure = 1) {
        return requestFactory(
          "patch",
          `/workspaces/${workspace_id}/configurations/trash/${id}?load_full_structure=${load_full_structure}`
        )
      },
      data: {
        delete(workspace_id, configuration_id) {
          return requestFactory(
            "delete",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/data`
          )
        }
      },
      dataPreview: {
        in: {
          list(
            workspace_id,
            configuration_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            name_filter = ""
          ) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/data/input/preview`,
              { offset, limit, load_full_structure, name_filter }
            )
          },
          retrieve(workspace_id, configuration_id, preview_id, load_full_structure = 0) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/data/input/preview/${preview_id}`,
              { load_full_structure }
            )
          }
        },
        out: {
          list(
            workspace_id,
            configuration_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            name_filter = ""
          ) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/data/output/preview`,
              { offset, limit, load_full_structure, name_filter }
            )
          },
          retrieve(workspace_id, configuration_id, preview_id, load_full_structure = 0) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/data/output/preview/${preview_id}`,
              { load_full_structure }
            )
          }
        }
      },
      job: {
        run(workspace_id, configuration_id, load_full_structure = 0) {
          return requestFactory(
            "post",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs?load_full_structure=${load_full_structure}`
          )
        },
        list(
          workspace_id,
          configuration_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory(
            "get",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs`,
            {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir,
              include_settings: 0
            }
          )
        },
        retrieve(workspace_id, configuration_id, job_id, load_full_structure = 0) {
          return requestFactory(
            "get",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs/${job_id}`,
            { load_full_structure }
          )
        },
        cancel(workspace_id, configuration_id, job_id, load_full_structure = 0) {
          return requestFactory(
            "delete",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs/${job_id}?load_full_structure=${load_full_structure}`
          )
        },
        history: {
          list(
            workspace_id,
            configuration_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs/${job_id}/history`,
              { offset, limit, load_full_structure, order_by, order_dir, include_settings: 0 }
            )
          },
          retrieve(workspace_id, configuration_id, job_id, history_id, load_full_structure = 0) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs/${job_id}/history/${history_id}`,
              { load_full_structure }
            )
          }
        },
        log: {
          list(
            workspace_id,
            configuration_id,
            job_id,
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC"
          ) {
            return requestFactory(
              "get",
              `/workspaces/${workspace_id}/configurations/${configuration_id}/jobs/${job_id}/logs`,
              { offset, limit, load_full_structure, order_dir, order_by }
            )
          }
        }
      },
      history: {
        list(
          workspace_id,
          configuration_id,
          offset = 0,
          limit = 50,
          load_full_structure = 0,
          order_by = "id",
          order_dir = "DESC"
        ) {
          return requestFactory(
            "get",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/history`,
            {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir
            }
          )
        },
        retrieve(workspace_id, configuration_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "get",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/history/${history_id}`,
            { load_full_structure }
          )
        },
        revertTo(workspace_id, configuration_id, history_id, load_full_structure = 0) {
          return requestFactory(
            "patch",
            `/workspaces/${workspace_id}/configurations/${configuration_id}/history/${history_id}?load_full_structure=${load_full_structure}`
          )
        }
      }
    },
    trash: {
      list(
        offset = 0,
        limit = 50,
        load_full_structure = 0,
        searched_text = null,
        item_type = null,
        order_dir = "DESC"
      ) {
        return requestFactory("get", "/trash", {
          offset,
          limit,
          load_full_structure,
          searched_text,
          item_type,
          order_dir
        })
      }
    },
    project: {
      configuration: {
        job: {
          list(
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC",
            include_deleted_entities_jobs = 0
          ) {
            return requestFactory("get", "/configuration_jobs", {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir,
              include_deleted_entities_jobs,
              include_settings: 0
            })
          }
        }
      },
      workspace: {
        job: {
          list(
            offset = 0,
            limit = 50,
            load_full_structure = 0,
            order_by = "id",
            order_dir = "DESC",
            include_deleted_workspaces_jobs = 0
          ) {
            return requestFactory("get", "/workspace_jobs", {
              offset,
              limit,
              load_full_structure,
              order_by,
              order_dir,
              include_deleted_workspaces_jobs
            })
          }
        }
      }
    },
    tag: {
      list(
        offset = 0,
        limit = 20,
        order_by = "id",
        order_dir = "ASC",
        load_full_structure = 0,
        name_filter = ""
      ) {
        return requestFactory("get", "/tags", {
          offset,
          limit,
          order_by,
          order_dir,
          load_full_structure,
          name_filter
        })
      },
      create(data) {
        return requestFactory("post", "/tags", data)
      },
      retrieve(tag_id, load_full_structure = 0) {
        return requestFactory("get", `/tags/${tag_id}`, { load_full_structure })
      },
      modify(tag_id, data, originData = {}, load_full_structure = 0) {
        return requestFactory(
          "patch",
          `/tags/${tag_id}?load_full_structure=${load_full_structure}`,
          data,
          originData
        )
      },
      delete(tag_id, load_full_structure = 0) {
        return requestFactory(
          "delete",
          `/tags/${tag_id}?load_full_structure=${load_full_structure}`
        )
      }
    },
    systemInfo() {
      return requestFactory("get", "/system_info")
    },
    authOptions() {
      return requestFactory("get", "/auth_options")
    },
    configurationSettigsSearch(search_text, offset, limit, load_full_structure = 0) {
      return requestFactory("get", "/configuration_settings_search", {
        search_text,
        offset,
        limit,
        load_full_structure
      })
    }
  }
}
