import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Map } from "immutable"
import { getFormValues } from "redux-form"
import moment from "moment"
import _get from "lodash/get"
import _toString from "lodash/toString"
import _toLower from "lodash/toLower"
import _orderBy from "lodash/orderBy"
import _noop from "lodash/noop"
import TimeAgo from "react-timeago"

// actions
import { deleteUser, modifyUser } from "actions/user.action"
import { showToast } from "actions/toast.action"
import { showLoadingBar, hideLoadingBar } from "actions/loadingBar.action"
import { setSortingOptions } from "actions/table.action"

// selectors
import { getUsersFuseData } from "selectors/user.selector"

// UI components
import SearchForm from "components/UI/components/SearchForm"
import Button from "components/UI/elements/Button"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import ToggleButton from "components/UI/elements/ToggleButton"
import IconButton from "components/UI/elements/IconButton"
import CreateUserModal from "./CreateUserModal"
import ConfirmModal from "components/UI/components/ConfirmModal"
import ModifyUserModal from "./ModifyUserModal"
import Avatar from "components/UI/elements/Avatar"

// constants, helpers
import { USER, MODAL, TOAST } from "sharedConstants"
import { api } from "api"
import { copyStringToClipboard } from "helpers/string.helper"

import "./UserList.css"

class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createUserModalOpen: false,
      userModal: Map({
        open: false,
        user: null
      }),
      deleteModal: Map({
        user: null,
        open: false,
        isLoading: false
      }),
      toggleUserRoleConfirmationModal: {
        open: false
      },
      resendingInvitation: false,
      copyingLink: false
    }
  }

  openDeleteModal = user => () => {
    this.setState(prevState => ({
      deleteModal: prevState.deleteModal.set("open", true).set("user", user)
    }))
  }

  closeDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: prevState.deleteModal.set("open", false).set("isLoading", false)
    }))
  }

  deleteUser = () => {
    if (!this.state.deleteModal.get("isLoading")) {
      const { deleteUser, showToast } = this.props
      const user = this.state.deleteModal.get("user")

      this.setState(prevState => ({
        deleteModal: prevState.deleteModal.set("isLoading", true)
      }))
      deleteUser(user.id)
        .then(() => {
          showToast("User has been deleted.", TOAST.TYPE.SUCCESS)
          this.closeDeleteModal()
        })
        .catch(this.closeDeleteModal)
    }
  }

  toggleUsersRole = user => {
    const { modifyUser, showToast } = this.props
    modifyUser(user.id, {
      role: user.role === USER.ROLE.ADMIN ? USER.ROLE.USER : USER.ROLE.ADMIN
    })
      .then(() => {
        showToast("User's role has been changed.", TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
    this.closeToggleUserRoleConfirmationModal()
  }

  openToggleUserRoleConfirmationModal = user => () => {
    const { authenticatedUser } = this.props

    if (authenticatedUser.data.id === user.id) {
      this.setState({
        toggleUserRoleConfirmationModal: {
          open: true
        }
      })
    } else {
      this.toggleUsersRole(user)
    }
  }

  closeToggleUserRoleConfirmationModal = () => {
    this.setState({
      toggleUserRoleConfirmationModal: {
        open: false
      }
    })
  }

  toggleUsersDisabled = user => () => {
    const { modifyUser, showToast } = this.props
    modifyUser(user.id, {
      disabled: user.disabled ? 0 : 1
    })
      .then(() => {
        showToast(`User has been ${user.disabled ? "enabled" : "disabled"}.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
  }

  toggleUsersCanCreateWorkspace = user => () => {
    const { modifyUser, showToast } = this.props
    modifyUser(user.id, {
      can_create_workspaces: user.can_create_workspaces ? 0 : 1
    })
      .then(() => {
        showToast(
          `User is ${user.can_create_workspaces ? "unable" : "able"} to create a workspace.`,
          TOAST.TYPE.SUCCESS
        )
      })
      .catch(_noop)
  }

  toggleCreateUserModal = userType => {
    this.setState(prevState => ({
      createUserModalOpen: !prevState.createUserModalOpen,
      userType: userType ? userType : prevState.userType
    }))
  }

  openUserModal = user => () => {
    this.setState(prevState => ({
      userModal: prevState.userModal.set("open", true).set("user", {
        ...user.toJS(),
        can_create_workspaces: _toString(user.can_create_workspaces),
        can_create_dawgs: _toString(user.can_create_dawgs)
      })
    }))
  }

  closeUserModal = () => {
    this.setState(prevState => ({
      userModal: prevState.userModal.set("open", false)
    }))
  }

  resendInvitation = (name, email) => () => {
    const { showToast, showLoadingBar, hideLoadingBar } = this.props
    const { resendingInvitation } = this.state
    if (!resendingInvitation) {
      this.setState({
        resendingInvitation: true
      })
      showLoadingBar()
      api()
        .user.resendInvitation(email)
        .then(() => {
          hideLoadingBar()
          this.setState({
            resendingInvitation: false
          })
          showToast(`User has been re-invited.`, TOAST.TYPE.SUCCESS)
        })
        .catch(() => {
          hideLoadingBar()
          this.setState({
            resendingInvitation: false
          })
        })
    }
  }

  sortUsersBy = column => () => {
    const { filterValues, setSortingOptions } = this.props
    const orderBy = _get(filterValues, "orderBy")
    const orderDir = _get(filterValues, "orderDir", "DESC")

    if (orderBy === column) {
      // switch orderDir
      setSortingOptions("UsersSearch", column, orderDir === "ASC" ? "DESC" : "ASC")
    } else {
      setSortingOptions("UsersSearch", column, "ASC")
    }
  }

  copyInvitationLink = userId => async () => {
    try {
      this.setState({
        copyingLink: true
      })
      const response = await api().user.getInvitationLink(userId)
      const link = response.user_invitation_link
      copyStringToClipboard(link)
      this.setState({
        copyingLink: false
      })
      this.props.showToast("Invitation link has been copied to clipboard.", TOAST.TYPE.SUCCESS)
    } catch (err) {
      this.setState({
        copyingLink: false
      })
    }
  }

  render() {
    const { users, filterValues, authenticatedUser } = this.props
    const {
      createUserModalOpen,
      deleteModal,
      userModal,
      toggleUserRoleConfirmationModal,
      resendingInvitation,
      userType,
      copyingLink
    } = this.state
    const searchTerm = _get(filterValues, "search", "")
    const orderBy = _get(filterValues, "orderBy", "name")
    const orderDir = _get(filterValues, "orderDir", "ASC")

    const usersFiltered = searchTerm
      ? users
          .get("data")
          .search(searchTerm)
          .toArray()
          .filter(user => user.role !== USER.ROLE.SYSTEM)
      : users
          .get("data")
          .list.toArray()
          .filter(user => user.role !== USER.ROLE.SYSTEM)

    const usersSorted = _orderBy(
      usersFiltered,
      user => _toLower(_toString(user[orderBy])),
      _toLower(orderDir)
    )

    return (
      <section className="users-list">
        <PaperHeader className="users-list-header" size="small">
          <h3>Users</h3>
          <SearchForm
            placeholder="Search for name"
            className="users-search"
            initialValues={{ search: "", orderBy: "name", orderDir: "ASC" }}
            form="UsersSearch"
          />
          <div className="action-buttons">
            <Button
              onClick={() => this.toggleCreateUserModal("user")}
              color="white"
              size="big"
              data-cy="invite-user-button"
            >
              + Create user
            </Button>
            <Button onClick={() => this.toggleCreateUserModal("admin")} color="white" size="big">
              + Create admin
            </Button>
          </div>
        </PaperHeader>
        <Paper hasHeader={true}>
          <table className="table users" data-cy="users-table">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th className="username-col">
                  <button
                    type="button"
                    className={`sort-button ${orderBy === "name" ? "active" : ""}`}
                    onClick={this.sortUsersBy("name")}
                  >
                    Name
                    {(orderDir === "DESC" || orderBy !== "name") && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} />
                    )}
                    {orderDir === "ASC" && orderBy === "name" && (
                      <FontAwesomeIcon icon={["fas", "caret-up"]} />
                    )}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort-button ${orderBy === "email" ? "active" : ""}`}
                    onClick={this.sortUsersBy("email")}
                  >
                    Email
                    {(orderDir === "DESC" || orderBy !== "email") && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} />
                    )}
                    {orderDir === "ASC" && orderBy === "email" && (
                      <FontAwesomeIcon icon={["fas", "caret-up"]} />
                    )}
                  </button>
                </th>
                <th className="align-right last-login">
                  <button
                    type="button"
                    className={`sort-button ${orderBy === "last_login" ? "active" : ""}`}
                    onClick={this.sortUsersBy("last_login")}
                  >
                    Last login
                    {(orderDir === "DESC" || orderBy !== "last_login") && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} />
                    )}
                    {orderDir === "ASC" && orderBy === "last_login" && (
                      <FontAwesomeIcon icon={["fas", "caret-up"]} />
                    )}
                  </button>
                </th>
                <th className="role-column">
                  <button
                    type="button"
                    className={`sort-button ${orderBy === "role" ? "active" : ""}`}
                    onClick={this.sortUsersBy("role")}
                  >
                    Role
                    {(orderDir === "DESC" || orderBy !== "role") && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} />
                    )}
                    {orderDir === "ASC" && orderBy === "role" && (
                      <FontAwesomeIcon icon={["fas", "caret-up"]} />
                    )}
                  </button>
                </th>
                <th className="align-right">
                  <button
                    type="button"
                    className={`sort-button ${orderBy === "disabled" ? "active" : ""}`}
                    onClick={this.sortUsersBy("disabled")}
                  >
                    Enabled
                    {(orderDir === "DESC" || orderBy !== "disabled") && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} />
                    )}
                    {orderDir === "ASC" && orderBy === "disabled" && (
                      <FontAwesomeIcon icon={["fas", "caret-up"]} />
                    )}
                  </button>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {usersSorted.map(user => (
                <tr
                  key={user.id}
                  className={user.disabled ? "disabled-user" : ""}
                  data-cy={user.name}
                >
                  <td className="gravatar">
                    <Avatar
                      email={user.email}
                      name={user.name}
                      gravatarSize={100}
                      disabledLook={user.disabled === 1}
                    />
                  </td>
                  <td data-cy="user-name" className="bigger-font">
                    {user.name}
                  </td>
                  <td className="text-grey email-col">{user.email}</td>
                  <td
                    className={`text-grey align-right last-login ${
                      !user.last_login ? "never" : ""
                    }`}
                  >
                    {user.last_login && (
                      <TimeAgo
                        date={moment
                          .utc(user.last_login)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      />
                    )}
                    {!user.last_login && (
                      <React.Fragment>
                        <div>Never,</div>
                        <span
                          className={`resend-inv ${resendingInvitation ? "sending" : ""}`}
                          onClick={this.resendInvitation(user.name, user.email)}
                        >
                          Resend invitation
                        </span>{" "}
                        <span>or</span>{" "}
                        <span
                          className={`copy-inv-link ${copyingLink ? "copying" : ""}`}
                          onClick={this.copyInvitationLink(user.id)}
                        >
                          Copy invitation link
                        </span>
                      </React.Fragment>
                    )}
                  </td>
                  <td className="role-column" data-cy="role-cell">
                    <ToggleSwitch
                      width="106px"
                      name={`role-${user.id}`}
                      leftValue={USER.ROLE.ADMIN}
                      rightValue={USER.ROLE.USER}
                      checked={user.role}
                      handleToggle={this.openToggleUserRoleConfirmationModal(user)}
                    />
                  </td>
                  <td className="align-right toggle-column" data-cy="enabled-cell">
                    <ToggleButton
                      value={!user.disabled}
                      handleToggle={this.toggleUsersDisabled(user)}
                    />
                  </td>
                  <td className="align-right action-column two-icon">
                    <IconButton
                      color="yellow"
                      onClick={this.openUserModal(user)}
                      data-cy="modify-user-modal-button"
                    >
                      <FontAwesomeIcon icon={["far", "user-edit"]} />
                    </IconButton>
                    <IconButton
                      color="red"
                      onClick={this.openDeleteModal(user)}
                      data-cy="delete-user-button"
                    >
                      <FontAwesomeIcon icon={["far", "trash-alt"]} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
        <CreateUserModal
          open={createUserModalOpen}
          handleClose={this.toggleCreateUserModal}
          type={userType}
          initialValues={{ send_invitation_emails: true }}
        />
        <ModifyUserModal
          open={userModal.get("open")}
          initialValues={userModal.get("user")}
          handleClose={this.closeUserModal}
        />
        <ConfirmModal
          open={deleteModal.get("open")}
          type={MODAL.TYPE.DELETE}
          handleClose={this.closeDeleteModal}
          handleConfirm={this.deleteUser}
          title="Delete user"
          action="delete"
          what="user"
          item={deleteModal.getIn(["user", "name"], "")}
          isLoading={deleteModal.get("isLoading")}
        />
        <ConfirmModal
          open={toggleUserRoleConfirmationModal.open}
          type={MODAL.TYPE.SUCCESS}
          handleClose={this.closeToggleUserRoleConfirmationModal}
          handleConfirm={() => this.toggleUsersRole(authenticatedUser.data)}
          title="Change user role"
          text="By changing role to user, you will lose your admin rights. Do you really want to continue?"
        />
      </section>
    )
  }
}

UserList.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Map).isRequired,
  searchValues: PropTypes.object,
  showLoadingBar: PropTypes.func.isRequired,
  hideLoadingBar: PropTypes.func.isRequired,
  setSortingOptions: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  authenticatedUser: state.authenticatedUser,
  users: getUsersFuseData(state),
  filterValues: getFormValues("UsersSearch")(state)
})

export default connect(mapStateToProps, {
  deleteUser,
  modifyUser,
  showToast,
  showLoadingBar,
  hideLoadingBar,
  setSortingOptions
})(UserList)
