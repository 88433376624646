import { DAWG, TAG } from "sharedConstants"
import { fromJS, List, Map } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import DawgModel from "models/dawg.model"
import UserModel from "models/user.model"
import DawgJobModel from "models/dawgJob.model"
import SelectionSettingModel from "models/selectionSettings.model"

const initialState = Map({
  dawgs: null,
  selectionSettings: new SelectionSettingModel(),
  hasMoreItems: false,
  lastJobs: null
})

const createDawgModel = dawg =>
  new DawgModel({
    ...dawg,
    user: new UserModel(dawg.user),
    settings: fromJS(dawg.settings),
    frontend_settings: fromJS(dawg.frontend_settings),
    author: new UserModel(dawg.author),
    tags: List(dawg.tags),
    example_workspaces: List(dawg.example_workspaces)
  })

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${DAWG.ACTION.LIST}_FULFILLED`:
      if (payload.selection_settings.offset === 0) {
        return state
          .set("dawgs", List(_map(payload.dawgs, dawg => createDawgModel(dawg))))
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.dawgs.length === payload.selection_settings.limit)
      } else {
        return state
          .set(
            "dawgs",
            state.get("dawgs").concat(_map(payload.dawgs, dawg => createDawgModel(dawg)))
          )
          .set("selectionSettings", new SelectionSettingModel(payload.selection_settings))
          .set("hasMoreItems", payload.dawgs.length === payload.selection_settings.limit)
      }
    case `${DAWG.ACTION.RETRIEVE}_FULFILLED`:
      /*
       * Two situations may occur:
       * 1) state is null -> direct acces to dawg detail
       * 2) state is List() -> access to dawg detail from dawg list
       */
      if (state.get("dawgs") === null) {
        return state.set("dawgs", List([createDawgModel(payload)]))
      } else {
        const isAlreadyInList = state.get("dawgs").find(dawg => dawg.id === payload.id)
        if (isAlreadyInList) {
          return state.set(
            "dawgs",
            state
              .get("dawgs")
              .map(dawg => (dawg.id === payload.id ? createDawgModel(payload) : dawg))
          )
        } else {
          return state.set("dawgs", state.get("dawgs").push(createDawgModel(payload)))
        }
      }

    case `${DAWG.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "dawgs",
        state.get("dawgs").map(dawg => (dawg.id === payload.id ? createDawgModel(payload) : dawg))
      )

    case `${DAWG.ACTION.DELETE}_FULFILLED`:
      // payload === dawg id
      return state.set(
        "dawgs",
        state.get("dawgs").filterNot(dawg => dawg.id === payload)
      )

    case `${TAG.ACTION.ASSIGN_TAG_TO_DAWG}_FULFILLED`: {
      const dawgIndex = state.get("dawgs").findIndex(dawg => dawg.id === payload.dawg.id)
      if (dawgIndex !== -1) {
        return state.setIn(
          ["dawgs", dawgIndex, "tags"],
          state.getIn(["dawgs", dawgIndex, "tags"]).concat(payload.tag)
        )
      }
      return state
    }

    case `${TAG.ACTION.DELETE_TAG_FROM_DAWG}_FULFILLED`: {
      const dawgIndex = state.get("dawgs").findIndex(dawg => dawg.id === payload.dawg_id)
      if (dawgIndex !== -1) {
        return state.setIn(
          ["dawgs", dawgIndex, "tags"],
          state.getIn(["dawgs", dawgIndex, "tags"]).filterNot(tag => {
            return tag.id === payload.tag_id
          })
        )
      }
      return state
    }

    case DAWG.ACTION.LIST_LAST_JOBS:
      if (state.get("lastJobs") === null) {
        return state.set(
          "lastJobs",
          Map(
            _mapKeys(
              _map(payload, lastJob => new DawgJobModel(fromJS(lastJob.last_job))),
              "dawg_id"
            )
          )
        )
      } else {
        return state.set(
          "lastJobs",
          state.get("lastJobs").merge(
            _mapKeys(
              _map(payload, lastJob => new DawgJobModel(fromJS(lastJob.last_job))),
              "dawg_id"
            )
          )
        )
      }

    // create action calls dispatches LIST action because we haven't got fetched all the data ->
    // it's not possible to insert newly create item somewhere if there exists some filter or sorting rule
    // case `${DAWG.ACTION.CREATE}_FULFILLED`:
    // case `${DAWG.ACTION.CLONE}_FULFILLED`:

    default:
      return state
  }
}
