import React, { Component } from "react"
import { connect } from "react-redux"
import { Router, Route, Switch, Link } from "react-router-dom"
import { createBrowserHistory } from "history"
import { ToastContainer, toast as toastShow } from "react-toastify"
import LoadingBar from "react-redux-loading-bar"
import Bowser from "bowser"
import _get from "lodash/get"

// font awesome
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faCaretUp,
  faCaretDown,
  faSearch,
  faCodeBranch,
  faWrench,
  faTrashAlt as solidTrashAlt,
  faChevronLeft as chevronLeftSolid,
  faPlay as faPlaySolid,
  faPause,
  faExclamation,
  faCheck,
  faHourglass,
  faTimes as faTimesSolid,
  faMinus,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faCloud,
  faCode,
  faInfoCircle,
  faStream as faStreamSolid,
  faFileContract,
  faUser as faUserSolid,
  faLifeRing,
  faMicrochip,
  faCopy as faCopySolid,
  faArrowCircleUp,
  faBan,
  faExclamationTriangle,
  faCogs as faCogsSolid,
  faTerminal as faTerminalSolid,
  faDatabase as faDatabaseSolid
} from "@fortawesome/free-solid-svg-icons"

import {
  faUser,
  faEnvelope,
  faTrashAlt as trashAltRegular,
  faClone,
  faPaperPlane,
  faEdit,
  faEyeSlash,
  faEye as faEyeRegular,
  faUserCircle
} from "@fortawesome/free-regular-svg-icons"

import {
  faQuestion,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faLocation,
  faPlay,
  faPlus,
  faUserEdit,
  faRedo,
  faPowerOff as faPowerOffRegular,
  faLevelUp,
  faStream,
  faFilter,
  faDownload,
  faCogs as faCogsRegular,
  faUndo,
  faTag,
  faEraser,
  faUserPlus,
  faPlusCircle as faPlusCircleRegular,
  faSortSizeDownAlt,
  faSortAlphaDown,
  faSortAlphaUp,
  faCog,
  faExclamationTriangle as faExclamationTriangleRegular
} from "@fortawesome/pro-regular-svg-icons"

import { faEye, faPencil, faPowerOff, faCopy } from "@fortawesome/pro-light-svg-icons"

import {
  faTrafficCone,
  faSignOut,
  faTachometerFastest,
  faChartNetwork,
  faSortAlt
} from "@fortawesome/pro-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// scroll to top
import ScrollToTop from "./ScrollToTop"

// auth helpers
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from "auth"

// ui components
import Login from "components/Login"
import OktaCallback from "components/Login/oktaCallback"
import LogoutPage from "components/Page/LogoutPage"
import AuthenticatedLayout from "components/UI/AuthenticatedLayout"
import PasswordResetSection from "components/Password/pages/PasswordReset"
import PasswordSetSection from "components/Password/pages/PasswordSet"
import getUserConfirmation from "./getUserConfirmation"
import AppUpdateModal from "./AppUpdateModal"

// constants, helpers
import { TOAST } from "sharedConstants"
import { getRoutePath } from "routes"

import "./App.css"
import "react-toastify/dist/ReactToastify.css"

// own history instance
export const history = createBrowserHistory({
  getUserConfirmation: getUserConfirmation
})

const LoginSection = userIsNotAuthenticatedRedir(Login)
const OktaCallbackSection = userIsNotAuthenticatedRedir(OktaCallback)
const AuthenticatedSection = userIsAuthenticatedRedir(AuthenticatedLayout)

library.add(
  faCaretUp,
  faCaretDown,
  faSignOut,
  faUser,
  faEnvelope,
  faQuestion,
  faSearch,
  faTimes,
  faTag,
  faChevronRight,
  faChevronLeft,
  faCodeBranch,
  faWrench,
  solidTrashAlt,
  chevronLeftSolid,
  faEye,
  faPencil,
  trashAltRegular,
  faLocation,
  faClone,
  faPowerOff,
  faPlay,
  faPlus,
  faPaperPlane,
  faUserEdit,
  faEdit,
  faRedo,
  faPlaySolid,
  faPause,
  faExclamation,
  faMinus,
  faCheck,
  faHourglass,
  faPowerOffRegular,
  faLevelUp,
  faStream,
  faFilter,
  faTrafficCone,
  faChevronUp,
  faChevronDown,
  faDownload,
  faTimesSolid,
  faCogsRegular,
  faUndo,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faCopy,
  faEraser,
  faUserPlus,
  faCloud,
  faCode,
  faExclamationTriangle,
  faPlusCircleRegular,
  faInfoCircle,
  faEyeSlash,
  faEyeRegular,
  faStreamSolid,
  faFileContract,
  faUserSolid,
  faLifeRing,
  faTachometerFastest,
  faMicrochip,
  faCopySolid,
  faSortSizeDownAlt,
  faSortAlphaDown,
  faSortAlphaUp,
  faArrowCircleUp,
  faChartNetwork,
  faUserCircle,
  faSortAlt,
  faBan,
  faCog,
  faExclamationTriangleRegular,
  faCogsSolid,
  faTerminalSolid,
  faDatabaseSolid
)

/*
 * Toast custom close button
 */
const CloseButton = ({ closeToast }) => (
  <span className="toast-close" onClick={closeToast}>
    <FontAwesomeIcon icon={["fas", "times"]} />
  </span>
)

const ToastContent = ({ text, route, closeToast, type }) => {
  const renderToastIcon = () => {
    let faIcon
    if (type === TOAST.TYPE.ERROR) {
      faIcon = "exclamation-triangle"
    } else if (type === TOAST.TYPE.SUCCESS) {
      faIcon = "check"
    } else {
      faIcon = "cogs"
    }
    return (
      <span className="toast-icon">
        <FontAwesomeIcon icon={["fas", faIcon]} />
      </span>
    )
  }

  if (route) {
    return (
      <React.Fragment>
        {renderToastIcon()}

        <span className="toast-text" data-cy="toast-text">
          {text}
          <Link
            to={{ pathname: route, state: { previous: true } }}
            className="link"
            data-cy="toast-link"
            onClick={closeToast}
          >
            View <FontAwesomeIcon icon={["far", "chevron-right"]} />
          </Link>
        </span>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {renderToastIcon()}
        <span className="toast-text" data-cy="toast-text">
          {text}
        </span>
      </React.Fragment>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.registration = false
    this.state = {
      isValidBrowser: true,
      invalidBrowserWarningClosed: false,
      newServiceWorkerDetected: false
    }

    window.addEventListener("blur", this.dismissAllToasts, false)
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.dismissAllToasts, false)
    document.removeEventListener("onNewServiceWorker", this.handleNewServiceWorker)
  }

  dismissAllToasts = () => {
    toastShow.dismiss()
  }

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies({
      chrome: ">=58",
      edge: ">=16",
      firefox: ">=54",
      chromium: ">=58",
      safari: ">=10.1",
      opera: ">=44"
    })
    this.setState({
      isValidBrowser
    })
    document.addEventListener("onNewServiceWorker", this.handleNewServiceWorker)
  }

  componentDidUpdate(prevProps) {
    const { toast } = this.props
    if (toast.id !== prevProps.toast.id) {
      if (this.toastId && toast.message === prevProps.toast.message) {
        toastShow.dismiss(this.toastId)
      }
      this.toastId = this.notify(toast)
    }
  }

  notify = data => {
    const message = _get(data, "message", "")
    const wider = message.length > 120
    // display notification only if the app is visible
    switch (data.type) {
      case TOAST.TYPE.ERROR:
        return toastShow.error(<ToastContent text={message} type={data.type} />, {
          position: toastShow.POSITION.TOP_RIGHT,
          className: `toast toast-error ${wider ? "wide" : ""} ${data.route ? "with-route" : ""}`,
          bodyClassName: "toast-body",
          closeButton: <CloseButton />,
          autoClose: false,
          draggable: false,
          closeOnClick: false
        })
      case TOAST.TYPE.SUCCESS:
        return toastShow.success(
          <ToastContent text={message} route={data.route} type={data.type} />,
          {
            position: toastShow.POSITION.TOP_RIGHT,
            className: `toast toast-success ${wider ? "wide" : ""} ${
              data.route ? "with-route" : ""
            }`,
            bodyClassName: "toast-body",
            closeButton: <CloseButton />,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            autoClose: 5000,
            draggable: false,
            closeOnClick: false
          }
        )
      case TOAST.TYPE.INFO:
      default:
        return toastShow.info(<ToastContent text={message} route={data.route} type={data.type} />, {
          position: toastShow.POSITION.TOP_RIGHT,
          className: `toast toast-info ${wider ? "wide" : ""} ${data.route ? "with-route" : ""}`,
          bodyClassName: "toast-body",
          closeButton: <CloseButton />,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          autoClose: false,
          draggable: false,
          closeOnClick: false
        })
    }
  }

  closeInvalidBrowserWarning = () => {
    this.setState({
      invalidBrowserWarningClosed: true
    })
  }

  handleNewServiceWorker = evt => {
    this.registration = evt.detail.registration
    this.setState({
      newServiceWorkerDetected: true
    })
  }

  render() {
    const { isValidBrowser, invalidBrowserWarningClosed, newServiceWorkerDetected } = this.state
    const { onLoadNewServiceWorkerAccept } = this.props
    return (
      <Router getUserConfirmation={getUserConfirmation} history={history}>
        <ScrollToTop>
          <div className="app">
            <LoadingBar
              style={{
                top: "0px",
                zIndex: "10000",
                backgroundColor: "#ffffff",
                position: "fixed",
                boxShadow: "0 0 6px 2px rgba(250, 190, 83, 0.5)"
              }}
            />
            {!isValidBrowser && !invalidBrowserWarningClosed && (
              <div className="invalid-browser-message">
                <div className="wrapper">
                  <div className="left-part">
                    <FontAwesomeIcon
                      icon={["far", "exclamation-triangle"]}
                      className="exclamation-icon"
                    />
                    <p>
                      Bear in mind that you use old and unsupported browser. We recommend upgrading
                      to the latest{" "}
                      <a
                        href="https://www.apple.com/safari/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safari
                      </a>
                      ,{" "}
                      <a
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chrome
                      </a>{" "}
                      or{" "}
                      <a
                        href="https://www.mozilla.org/en-US/firefox/new/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Firefox
                      </a>
                      .
                    </p>
                  </div>
                  <div className="right-part">
                    <span className="warning-close" onClick={this.closeInvalidBrowserWarning}>
                      Dismiss
                    </span>
                  </div>
                </div>
              </div>
            )}
            <AppUpdateModal
              open={newServiceWorkerDetected}
              updateFn={onLoadNewServiceWorkerAccept(this.registration)}
              history={history}
            />
            <Switch>
              <Route path={getRoutePath("password.reset")} component={PasswordResetSection} />
              <Route path={getRoutePath("password.set")} component={PasswordSetSection} />
              <Route path={getRoutePath("login")} component={LoginSection} />
              <Route path={getRoutePath("okta.callback")} component={OktaCallbackSection} />
              <Route path={getRoutePath("logout")} component={LogoutPage} />
              <Route path={getRoutePath("dashboard")} component={AuthenticatedSection} />
            </Switch>
            <ToastContainer
              className="custom-toastify"
              autoClose={5000}
              hideProgressBar={true}
              pauseOnHover={true}
              pauseOnFocusLoss={false}
            />
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

function mapStateToProps({ toast }) {
  return { toast }
}

export default connect(mapStateToProps)(App)
