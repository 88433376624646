import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, Map, List } from "immutable"
import _isFunction from "lodash/isFunction"

// ui elements
import ListItem, {
  DataProcessed,
  Author,
  LastRun,
  ItemName,
  ItemStatus,
  Schedules,
  Row,
  Tags,
  DawgWorkspaces
} from "components/UI/DawgWsShared/ListItem"

// helpers, routes
import { getRoutePath } from "routes"

class DawgListItem extends PureComponent {
  componentDidMount() {
    const { onInit } = this.props
    if (_isFunction(onInit)) {
      onInit()
    }
  }

  render() {
    const {
      job,
      dawg,
      selectFilterTag,
      innerRef,
      expandedSchedules,
      expandDawgSchedules
    } = this.props

    const authorName = dawg.getIn(["author", "name"])
    const schedulesSetting = dawg.getIn(["settings", "schedules"])
    const scheduleExists = List.isList(schedulesSetting) && schedulesSetting.size > 0

    return (
      <ListItem key={dawg.id} isDisabled={dawg.disabled} innerRef={innerRef} job={job}>
        <Row>
          <ItemName
            route={{
              pathname: getRoutePath("dawg.show", { id: dawg.id }),
              state: {
                previous: true
              }
            }}
            name={dawg.name}
          />
          <ItemStatus className="dawg-status" job={job} />
        </Row>
        <Row className="flex-row">
          <DataProcessed job={job} />
          <LastRun datetime={dawg.last_run} />
          <Author authorName={authorName} />
        </Row>
        {dawg.workspaces_count > 0 && (
          <Row>
            <DawgWorkspaces
              exampleWorkspaces={dawg.example_workspaces}
              workspacesCount={dawg.workspaces_count}
            />
          </Row>
        )}
        {scheduleExists && (
          <Row>
            <Schedules
              item={dawg}
              expandedSchedules={expandedSchedules}
              expandItemSchedules={expandDawgSchedules}
            />
          </Row>
        )}
        <Tags tags={dawg.tags} selectFilterTag={selectFilterTag} />
      </ListItem>
    )
  }
}

DawgListItem.propTypes = {
  job: PropTypes.instanceOf(Record),
  dawg: PropTypes.instanceOf(Record).isRequired,
  selectFilterTag: PropTypes.func.isRequired,
  expandedSchedules: PropTypes.instanceOf(Map).isRequired,
  expandDawgSchedules: PropTypes.func.isRequired,
  onInit: PropTypes.func
}

export default React.forwardRef((props, ref) => <DawgListItem innerRef={ref} {...props} />)
