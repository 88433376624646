import { Record, List } from "immutable"
import UserModel from "./user.model"

const Dawg = Record({
  id: 0,
  user: new UserModel(),
  name: "",
  description: "",
  settings: null,
  tags: List(),
  frontend_settings: null,
  disabled: 0,
  last_run: "",
  last_run_duration: "",
  author: new UserModel(),
  created: "",
  example_workspaces: List(),
  workspaces_count: 0
})

export default Dawg
