import { createSelector } from "reselect"
import { Map, List } from "immutable"
import _toLower from "lodash/toLower"

import { initFuseSearch } from "helpers/table.helper"

// initial components state is null and they're fetched on
// authenticated layout mount (only once when app loads)
export const isComponentsFulfilled = state => state.components !== null

export const getComponentsData = state => state.components

export const getComponentsFuseData = createSelector([getComponentsData], components => {
  if (components) {
    return Map({
      isFulfilled: true,
      data: initFuseSearch(
        components.toList().sortBy(component => _toLower(component.name)),
        ["name"]
      )
    })
  } else {
    return Map({
      isFulfilled: false,
      data: initFuseSearch(List(), ["name"])
    })
  }
})
