import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record, Map, List } from "immutable"
import { connect } from "react-redux"
import _noop from "lodash/noop"
import _toInteger from "lodash/toInteger"
import _get from "lodash/get"

import {
  fetchDawgAcl,
  createDawgAcl,
  modifyDawgAcl,
  deleteDawgAcl,
  fetchDawgUsersAcl
} from "actions/acl.action"
import {
  retrieveDawg,
  deleteDawg,
  modifyDawg,
  assignTagToDawg,
  unassignTagFromDawg
} from "actions/dawg.action"
import { listDawgJob, runDawgJob, cancelDawgJob, refreshLastDawgJob } from "actions/dawgJob.action"
import { enableLogout, disableLogout } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import {
  fetchDawgWorkspaceList,
  modifyDawgWorkspace,
  deleteWorkspaceFromDawg,
  modifyDawgWorkspacesBulk
} from "actions/dawgWorkspace.action"
import {
  runWorkspaceJob,
  cancelWorkspaceJob,
  listDawgWorkspaceJob,
  clearCachedWorkspaceJobs
} from "actions/workspaceJob.action"
import { clearCachedWorkspaceHistory } from "actions/workspaceHistory.action"

import { getDawgRecord } from "selectors/dawg.selector"
import { getUsersDawgAcl } from "selectors/usersAcl.selector"
import { getDawgAclsData } from "selectors/acl.selector"
import { getLastDawgJobsData } from "selectors/dawgJob.selector"
import {
  getDawgWorkspacesFuseData,
  areDawgWorkspacesFulfilled,
  getDawgWorkspacesData,
  getAreDawgWorkspacesFetching,
  getAreDawgWorkspacesModifying
} from "selectors/dawgWorkspace.selector"
import { getDawgDashboardWorkspaceJobs } from "selectors/dawgWorkspaceJob.selector"

import { hasWritePermission } from "helpers/authenticatedUser.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { MODAL, TOAST, INTERVAL, DAWG } from "sharedConstants"
import { getRoutePath } from "routes"

import Header from "components/UI/DawgWsShared/DetailHeader"
import ConfirmModal from "components/UI/components/ConfirmModal"
import DescriptionCard from "components/UI/components/DescriptionCard"
import SetScheduleCard from "components/UI/DawgWsShared/SetSchedule"
import EntityJobsCard from "components/UI/DawgWsShared/EntityJobsCard"
import DataFlowComponent from "components/UI/DawgWsShared/DataFlow"
import WorkspaceJobsCard from "components/UI/DawgWsShared/EntityChildrenJobsCard"

import { api } from "api"

import "./DawgDetail.css"

const initialState = {
  confirmModalVariables: Map({
    open: false,
    type: "",
    handleConfirm: () => {},
    title: "",
    action: "",
    what: "",
    text: "",
    isLoading: false
  }),
  highlightedWorkspaces: Map({
    active: false,
    ids: []
  }),
  editModes: Map({
    dawgName: false,
    description: false,
    schedule: false
  }),
  workspaceStatuses: Map(),
  dag: Map({
    previewMode: false,
    // 1 means sort, 0 means cancel
    callbackValue: null
  }),
  isDawgReloading: true
}

class DawgDetail extends PureComponent {
  runningCalls = {
    lastDawgJobs: false,
    lastWorkspaceJobs: false,
    dawgWorkspacesLastJobs: false
  }

  constructor(props) {
    super(props)
    this.state = initialState
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this._init()
  }

  _init() {
    const {
      retrieveDawg,
      fetchDawgAcl,
      fetchDawgWorkspaceList,
      listDawgJob,
      clearCachedWorkspaceHistory,
      clearCachedWorkspaceJobs,
      match: {
        params: { id }
      }
    } = this.props

    clearCachedWorkspaceJobs()
    clearCachedWorkspaceHistory()

    retrieveDawg(id)
      .then(() => {
        this.setState({ isDawgReloading: false })
      })
      .catch(() => {
        this.setState({ isDawgReloading: false })
      })

    fetchDawgAcl(id).catch(_noop)
    fetchDawgWorkspaceList(id)
      .then(response => {
        this.fetchDawgWorkspacesLastJobs()
        this.intervalId = setInterval(() => {
          this.refreshLastDawgJob()
          this.fetchLastWorkspaceJobs(false)
          this.fetchDawgWorkspacesLastJobs()
        }, INTERVAL.DAWG_SHOW)
      })
      .catch(_noop)

    this.runningCalls.lastDawgJobs = true
    listDawgJob(id, 0, DAWG.JOB.LOADING_LIMIT, 0)
      .then(() => {
        this.runningCalls.lastDawgJobs = false
      })
      .catch(() => {
        this.runningCalls.lastDawgJobs = false
      })
    this.fetchLastWorkspaceJobs()
  }

  _cleanup = (clearState = false) => {
    clearInterval(this.intervalId)
    this.pendingPromises.cancelAll()
    this.runningCalls = {
      lastWorkspaceJobs: false,
      lastConfigurationJobs: false,
      workspaceConfigurationsLastJobs: false
    }

    if (clearState) {
      this.setState(initialState)
    }
  }

  componentWillUnmount() {
    this._cleanup()
  }

  refreshLastDawgJob = () => {
    if (!this.runningCalls.lastDawgJobs) {
      this.runningCalls.lastDawgJobs = true
      const {
        refreshLastDawgJob,
        match: {
          params: { id }
        }
      } = this.props
      refreshLastDawgJob(id, 0)
        .then(() => {
          this.runningCalls.lastDawgJobs = false
        })
        .catch(() => {
          this.runningCalls.lastDawgJobs = false
        })
    }
  }

  fetchLastWorkspaceJobs = async (promise = true) => {
    if (!this.runningCalls.lastWorkspaceJobs) {
      this.runningCalls.lastWorkspaceJobs = true
      const {
        listDawgWorkspaceJob,
        match: {
          params: { id }
        }
      } = this.props

      try {
        await listDawgWorkspaceJob(id, 0, 6, 1, "status", "DESC", promise)
      } catch (err) {
      } finally {
        this.runningCalls.lastWorkspaceJobs = false
      }
    }
  }

  fetchDawgWorkspacesLastJobs = async () => {
    if (!this.runningCalls.dawgWorkspacesLastJobs) {
      const { dawgWorkspaces } = this.props
      this.runningCalls.dawgWorkspacesLastJobs = true
      const { workspaceStatuses } = this.state
      const [...workspaceIds] = dawgWorkspaces.keys()
      if (workspaceIds.length > 0) {
        const lastWsJobsPromise = this.pendingPromises.create(
          api().workspace.listLastJobs(workspaceIds.map(val => _toInteger(val)))
        )
        try {
          const response = await lastWsJobsPromise.promise
          if (response.last_workspace_jobs.length > 0) {
            const currentStatuses = Map(
              response.last_workspace_jobs.reduce((returnObj, job) => {
                returnObj[job.workspace_id] = Map({
                  id: _get(job, "last_job.id"),
                  status: _get(job, "last_job.status")
                })
                return returnObj
              }, {})
            )

            if (!workspaceStatuses.equals(currentStatuses)) {
              this.setState({
                workspaceStatuses: currentStatuses
              })
            }
          }
        } catch (err) {
        } finally {
          this.runningCalls.dawgWorkspacesLastJobs = false
        }
      } else {
        this.runningCalls.dawgWorkspacesLastJobs = false
      }
    }
  }

  toggleConfirmModal = (
    title,
    action,
    what,
    handleConfirm,
    htmlText = [],
    type = MODAL.TYPE.SUCCESS,
    stopInterval = false
  ) => () => {
    const open = this.state.confirmModalVariables.get("open")
    if (open) {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", false)
          .set("isLoading", false)
      }))
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          this.refreshLastDawgJob()
          this.fetchLastWorkspaceJobs(false)
          this.fetchDawgWorkspacesLastJobs()
        }, INTERVAL.DAWG_SHOW)
      }
    } else {
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables
          .set("open", true)
          .set("type", type)
          .set("handleConfirm", handleConfirm)
          .set("title", title)
          .set("action", action)
          .set("what", what)
          .set("htmlText", htmlText)
      }))
      if (stopInterval) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    }
  }

  deleteDawg = () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { deleteDawg, showToast, history } = this.props
      const { id: dawgId } = this.props.match.params
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      deleteDawg(dawgId)
        .then(() => {
          showToast("DAWG has been deleted.", TOAST.TYPE.SUCCESS)
          history.push(getRoutePath("dawg.list"))
        })
        .catch(() => {
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  modifyDawg = async (
    parameters,
    previousParameters = {},
    message = "",
    toastType = TOAST.TYPE.SUCCESS
  ) => {
    const { modifyDawg, showToast, dawg } = this.props
    await modifyDawg(dawg.id, parameters, previousParameters).then(() => {
      showToast(message ? message : "DAWG has been modified.", toastType)
    })
  }

  runDawg = async () => {
    if (!this.state.confirmModalVariables.get("isLoading")) {
      const { showToast, runDawgJob } = this.props
      const { id: dawgId } = this.props.match.params
      this.runningCalls.lastDawgJobs = true

      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      runDawgJob(dawgId, 0)
        .then(response => {
          showToast(
            "DAWG job has started.",
            TOAST.TYPE.SUCCESS,
            getRoutePath("dawg.dawgJob.show", {
              id: response.value.dawg_job.dawg_id,
              aid: response.value.dawg_job.id
            })
          )
          this.runningCalls.lastDawgJobs = false
          this.toggleConfirmModal()()
        })
        .catch(error => {
          this.runningCalls.lastDawgJobs = false
          this.setState(prevState => ({
            confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
          }))
        })
    }
  }

  cancelDawgJob = async () => {
    const {
      lastDawgJobs,
      cancelDawgJob,
      showToast,
      match: {
        params: { id }
      }
    } = this.props
    if (List.isList(lastDawgJobs) && lastDawgJobs.size > 0) {
      const job = lastDawgJobs.get(0)
      this.runningCalls.lastDawgJobs = true
      this.setState(prevState => ({
        confirmModalVariables: prevState.confirmModalVariables.set("isLoading", true)
      }))
      try {
        await cancelDawgJob(id, job.id)
        showToast("DAWG job is being cancelled.", TOAST.TYPE.SUCCESS)
        this.runningCalls.lastDawgJobs = false
        this.toggleConfirmModal()()
      } catch (err) {
        this.runningCalls.lastDawgJobs = false
        this.setState(prevState => ({
          confirmModalVariables: prevState.confirmModalVariables.set("isLoading", false)
        }))
      }
    } else {
      this.toggleConfirmModal()()
    }
  }

  toggleEditMode = editMode => (cb = _noop) => () => {
    this.setState(
      prevState => ({
        editModes: prevState.editModes.set(editMode, !prevState.editModes.get(editMode))
      }),
      () => {
        let isEditing = false
        this.state.editModes.forEach(mode => {
          if (mode) {
            isEditing = true
          }
        })

        if (isEditing) {
          this.props.disableLogout()
        } else {
          this.props.enableLogout()
        }
        cb()
      }
    )
  }

  render() {
    const {
      dawg,
      usersAcl,
      createDawgAcl,
      modifyDawgAcl,
      deleteDawgAcl,
      fetchDawgUsersAcl,
      dawgUsers,
      assignTagToDawg,
      unassignTagFromDawg,
      lastDawgJobs,
      dawgWorkspacesFuseData,
      areDawgWorkspacesFulfilled,
      dawgWorkspaces,
      areDawgWorkspacesFetching,
      areDawgWorkspacesModifying,
      modifyDawgWorkspace,
      deleteWorkspaceFromDawg,
      runWorkspaceJob,
      cancelWorkspaceJob,
      workspaceJobs,
      modifyDawgWorkspacesBulk
    } = this.props
    const { editModes, isDawgReloading, confirmModalVariables, workspaceStatuses } = this.state
    const isEditable = hasWritePermission(usersAcl)
    let currentlyEditing = ""
    editModes.forEach((mode, key) => {
      if (mode) {
        switch (key) {
          case "dawgName": {
            currentlyEditing = "dawg name"
            break
          }
          case "description": {
            currentlyEditing = "dawg description"
            break
          }
          case "schedule": {
            currentlyEditing = "dawg schedule"
            break
          }
          default:
            currentlyEditing = ""
        }
      }
    })

    return (
      <section className="dawg-detail">
        {dawg && (
          <React.Fragment>
            <Header
              entity={dawg}
              createAcl={createDawgAcl}
              modifyAcl={modifyDawgAcl}
              deleteAcl={deleteDawgAcl}
              entityUsers={dawgUsers}
              entityName="dawg"
              fetchUsersAcl={fetchDawgUsersAcl}
              handleEntityDelete={this.toggleConfirmModal(
                "Delete Dawg",
                "",
                "",
                this.deleteDawg,
                [
                  <p key="0">
                    Do you want to delete this dawg?{" "}
                    <strong>Beware the step is irreversible!</strong>
                  </p>
                ],
                MODAL.TYPE.DELETE,
                true
              )}
              handleEntityModify={this.modifyDawg}
              handleEntityRun={this.toggleConfirmModal("Run Dawg", "run", "dawg", this.runDawg)}
              handleEntityCancel={this.toggleConfirmModal(
                "Cancel dawg",
                "cancel",
                "dawg",
                this.cancelDawgJob,
                [],
                MODAL.TYPE.CANCEL
              )}
              assignTagToEntity={assignTagToDawg}
              unassignTagFromEntity={unassignTagFromDawg}
              isEditable={isEditable}
              currentlyEditing={currentlyEditing}
              toggleNameFormEditMode={this.toggleEditMode("dawgName")}
              nameFormEditMode={editModes.get("dawgName")}
              entityStatus={List.isList(lastDawgJobs) ? lastDawgJobs.getIn([0, "status"]) : null}
              isEntityReloading={isDawgReloading}
            />
            <DataFlowComponent
              entitiesFuseSearchInstance={dawgWorkspacesFuseData}
              entityName="dawg"
              entity={dawg}
              isEntitiesFulfilled={areDawgWorkspacesFulfilled}
              isEditable={isEditable}
              entityStatuses={workspaceStatuses}
              isChildrenFetching={areDawgWorkspacesFetching}
              isChildrenModifying={areDawgWorkspacesModifying}
              childEntities={dawgWorkspaces}
              modifyEntity={modifyDawgWorkspace}
              deleteEntity={deleteWorkspaceFromDawg}
              runEntityJob={runWorkspaceJob}
              cancelEntityJob={cancelWorkspaceJob}
              modifyEntitiesBulk={modifyDawgWorkspacesBulk}
            />
            <div className="two-column-layout">
              <div className="left-column">
                <DescriptionCard
                  form="DawgDescriptionForm"
                  initialValues={{ description: dawg.description }}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  onSubmit={this.modifyDawg}
                  toggleEditMode={this.toggleEditMode("description")}
                  editMode={editModes.get("description")}
                  readyToEdit={!isDawgReloading}
                />
                <SetScheduleCard
                  entity={dawg}
                  showToast={this.props.showToast}
                  modifyEntity={this.props.modifyDawg}
                  isEditable={isEditable}
                  currentlyEditing={currentlyEditing}
                  toggleEditMode={this.toggleEditMode("schedule")}
                  editMode={editModes.get("schedule")}
                  readyToEdit={!isDawgReloading}
                />
              </div>

              <div className="right-column">
                <EntityJobsCard entityName="dawg" jobs={lastDawgJobs} />
                {List.isList(workspaceJobs) && (
                  <WorkspaceJobsCard jobs={workspaceJobs} rootEntityName="dawg" />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        <ConfirmModal
          open={confirmModalVariables.get("open")}
          type={confirmModalVariables.get("type")}
          handleClose={this.toggleConfirmModal()}
          handleConfirm={confirmModalVariables.get("handleConfirm")}
          title={confirmModalVariables.get("title")}
          action={confirmModalVariables.get("action")}
          what={confirmModalVariables.get("what")}
          isLoading={confirmModalVariables.get("isLoading")}
          htmlText={confirmModalVariables.get("htmlText")}
        />
      </section>
    )
  }
}

DawgDetail.propTypes = {
  dawg: PropTypes.instanceOf(Record),
  retrieveDawg: PropTypes.func.isRequired,
  fetchDawgAcl: PropTypes.func.isRequired,
  usersAcl: PropTypes.instanceOf(Record),
  createDawgAcl: PropTypes.func.isRequired,
  modifyDawgAcl: PropTypes.func.isRequired,
  deleteDawgAcl: PropTypes.func.isRequired,
  dawgUsers: PropTypes.instanceOf(List),
  fetchDawgUsersAcl: PropTypes.func.isRequired,
  deleteDawg: PropTypes.func.isRequired,
  modifyDawg: PropTypes.func.isRequired,
  listDawgJob: PropTypes.func.isRequired,
  runDawgJob: PropTypes.func.isRequired,
  cancelDawgJob: PropTypes.func.isRequired,
  refreshLastDawgJob: PropTypes.func.isRequired,
  lastDawgJobs: PropTypes.instanceOf(List),
  assignTagToDawg: PropTypes.func.isRequired,
  unassignTagFromDawg: PropTypes.func.isRequired,
  enableLogout: PropTypes.func.isRequired,
  disableLogout: PropTypes.func.isRequired,
  dawgWorkspacesFuseData: PropTypes.instanceOf(Map).isRequired,
  areDawgWorkspacesFulfilled: PropTypes.bool.isRequired,
  areDawgWorkspacesFetching: PropTypes.bool.isRequired,
  areDawgWorkspacesModifying: PropTypes.bool,
  modifyDawgWorkspace: PropTypes.func.isRequired,
  deleteWorkspaceFromDawg: PropTypes.func.isRequired,
  runWorkspaceJob: PropTypes.func.isRequired,
  cancelWorkspaceJob: PropTypes.func.isRequired,
  listDawgWorkspaceJob: PropTypes.func.isRequired,
  workspaceJobs: PropTypes.instanceOf(List),
  clearCachedWorkspaceHistory: PropTypes.func.isRequired,
  clearCachedWorkspaceJobs: PropTypes.func.isRequired,
  modifyDawgWorkspacesBulk: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  dawg: getDawgRecord(state, ownProps.match.params.id),
  usersAcl: getUsersDawgAcl(state, ownProps.match.params.id),
  dawgUsers: getDawgAclsData(state, ownProps.match.params.id),
  lastDawgJobs: getLastDawgJobsData(state),
  dawgWorkspacesFuseData: getDawgWorkspacesFuseData(state, ownProps.match.params.id),
  areDawgWorkspacesFulfilled: areDawgWorkspacesFulfilled(state, ownProps.match.params.id),
  dawgWorkspaces: getDawgWorkspacesData(state, ownProps.match.params.id),
  areDawgWorkspacesFetching: getAreDawgWorkspacesFetching(state),
  areDawgWorkspacesModifying: getAreDawgWorkspacesModifying(state, ownProps.match.params.id),
  workspaceJobs: getDawgDashboardWorkspaceJobs(state, ownProps.match.params.id)
})

export default connect(mapStateToProps, {
  retrieveDawg,
  fetchDawgAcl,
  createDawgAcl,
  modifyDawgAcl,
  deleteDawgAcl,
  fetchDawgUsersAcl,
  deleteDawg,
  modifyDawg,
  listDawgJob,
  runDawgJob,
  cancelDawgJob,
  refreshLastDawgJob,
  assignTagToDawg,
  unassignTagFromDawg,
  enableLogout,
  disableLogout,
  showToast,
  fetchDawgWorkspaceList,
  modifyDawgWorkspace,
  deleteWorkspaceFromDawg,
  runWorkspaceJob,
  cancelWorkspaceJob,
  listDawgWorkspaceJob,
  clearCachedWorkspaceHistory,
  clearCachedWorkspaceJobs,
  modifyDawgWorkspacesBulk
})(DawgDetail)
