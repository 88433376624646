import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _toInteger from "lodash/toInteger"

import { showToast } from "actions/toast.action"
import TextField from "components/UI/elements/TextField"
import Button from "components/UI/elements/Button"
import { required, email } from "helpers/validators.helper"
import PendingPromise from "helpers/pendingPromise.helper"
import { login } from "actions/authenticatedUser.action"
import { getRoutePath } from "routes"
import { api } from "api"
import ReCAPTCHA from "react-google-recaptcha"

import okta from "images/white-okta-logo.png"
import logo from "images/meiro-symbol-white-transparent.svg"

let credentialsLoginEnabled = 0,
  oktaLoginEnabled = 0,
  recaptchaSiteKey = null
if (process.env.NODE_ENV === "production") {
  credentialsLoginEnabled = "[[CREDENTIALS_LOGIN_ENABLED]]"
  oktaLoginEnabled = "[[OKTA_LOGIN_ENABLED]]"
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  credentialsLoginEnabled = process.env.REACT_APP_CREDENTIALS_LOGIN_ENABLED
  oktaLoginEnabled = process.env.REACT_APP_OKTA_LOGIN_ENABLED
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

class Login extends Component {
  constructor(props) {
    super(props)
    let showGeneralTermsCheckbox = false
    let meiroGeneralTermsAccepted
    try {
      meiroGeneralTermsAccepted = window.localStorage.getItem("miGeneralTermsAccepted")
    } catch (err) {}
    if (meiroGeneralTermsAccepted !== "true") {
      showGeneralTermsCheckbox = true
    }
    this.state = {
      showGeneralTermsCheckbox,
      generalTermsCheckboxValue: false,
      auth: {
        options: null,
        loading: true
      },
      recaptchaToken: "",
      showRecaptcha: false
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const authOptionsRequest = this.pendingPromises.create(api().authOptions())
    authOptionsRequest.promise
      .then(response => {
        this.setState({
          auth: {
            options: response.authentication_options,
            loading: false
          }
        })
        this.pendingPromises.remove(authOptionsRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState(prevState => ({
            auth: {
              ...prevState.auth,
              loading: false
            }
          }))
        }
        this.pendingPromises.remove(authOptionsRequest)
      })
    document.body.classList.add("toastify-on-top")
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.auth.loading && prevState.auth.loading && this.state.oktaLoading) {
      this.redirectToOktaAuthLink()
    }
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    document.body.classList.remove("toastify-on-top")
  }

  onSubmit = values => {
    if (!this.state.loading) {
      this.setState({ loading: true })
      const { recaptchaToken } = this.state
      if (recaptchaToken) {
        values.recaptcha_token = recaptchaToken
      }
      this.props
        .login(values)
        .then(() => {
          try {
            if (window.localStorage.getItem("miGeneralTermsAccepted") !== "true") {
              window.localStorage.setItem("miGeneralTermsAccepted", "true")
            }
          } catch (err) {}
        })
        .catch(error => {
          if (error.response?.data?.show_recaptcha) {
            this.setState({
              showRecaptcha: true,
              loading: false
            })
          } else {
            this.setState({ loading: false })
          }
        })
    }
  }

  redirectToOktaAuthLink = () => {
    const { auth } = this.state
    const oktaLink = _get(auth, "options.okta_auth_link")
    window.open(oktaLink, "_self")
  }

  loginWithOkta = () => {
    try {
      const urlParams = new URLSearchParams(this.props.location.search)
      const redirect = urlParams.get("redirect")
      window.localStorage.setItem("redirectAfterOktaLoginTo", redirect)
    } catch (err) {}
    if (this.state.auth.loading) {
      this.setState({
        oktaLoading: true
      })
    } else {
      this.redirectToOktaAuthLink()
    }
  }

  toggleGeneralTermsCheckboxValue = () => {
    this.setState(prevState => ({
      generalTermsCheckboxValue: !prevState.generalTermsCheckboxValue
    }))
  }

  renderTermsGuidelinesCheckbox = () => {
    const { showGeneralTermsCheckbox, generalTermsCheckboxValue } = this.state
    if (showGeneralTermsCheckbox) {
      return (
        <div className="form-row checkbox-field general-terms">
          <label className="checkbox-label">
            I consent with{" "}
            <a href="https://meiro.io/general-terms/" target="_blank" rel="noopener noreferrer">
              Meiro General Terms
            </a>{" "}
            and{" "}
            <a
              href="https://meiro.io/user-security-guidelines/"
              target="_blank"
              rel="noopener noreferrer"
            >
              User Security Guidelines
            </a>
            <input
              type="checkbox"
              checked={generalTermsCheckboxValue}
              onChange={this.toggleGeneralTermsCheckboxValue}
              className="checkbox"
            />
            <span className="checkmark">
              <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
            </span>
          </label>
        </div>
      )
    }
  }

  setRecaptchaToken = recaptchaToken => {
    this.setState({ recaptchaToken })
  }

  render() {
    const { handleSubmit } = this.props
    const {
      showGeneralTermsCheckbox,
      generalTermsCheckboxValue,
      showRecaptcha,
      recaptchaToken
    } = this.state

    const showLoginForm = _toInteger(credentialsLoginEnabled) === 1
    const showOktaButton = _toInteger(oktaLoginEnabled) === 1

    return (
      <section className="login user-form-screens">
        <div className="form-wrapper">
          <img src={logo} className="logo" alt="meiro logo" />
          {showLoginForm && (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
              <h2>Login</h2>
              <div className="form-row">
                <Field
                  name="email"
                  component={TextField}
                  label="Email"
                  validate={[required, email]}
                  fullWidth={true}
                  placeholder="Email"
                  autoFocus
                />
              </div>
              <div className="form-row">
                <Field
                  name="password"
                  component={TextField}
                  label="Password"
                  placeholder="Password"
                  validate={required}
                  fullWidth={true}
                  type="password"
                />
              </div>
              {showRecaptcha && recaptchaSiteKey && (
                <div className="form-row recaptcha">
                  <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={this.setRecaptchaToken} />
                </div>
              )}
              <div className={`actions ${showGeneralTermsCheckbox} ? "with-checkbox" : ""`}>
                <Button
                  type="submit"
                  color="primary"
                  size="big"
                  fullWidth={true}
                  className={this.state.loading ? "loading" : ""}
                  disabled={
                    (showGeneralTermsCheckbox && !generalTermsCheckboxValue) ||
                    (showRecaptcha && !recaptchaToken)
                  }
                >
                  Login
                </Button>

                {showOktaButton && (
                  <Button
                    type="button"
                    color="blue"
                    size="big"
                    onClick={this.loginWithOkta}
                    className={`okta-login second-button ${
                      this.state.oktaLoading ? "loading" : ""
                    }`}
                    disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
                  >
                    Login with <img src={okta} alt="" />
                  </Button>
                )}
                {this.renderTermsGuidelinesCheckbox()}
                <Link
                  className="underform-link forgot-password"
                  to={getRoutePath("password.reset")}
                >
                  Forgot password?
                </Link>
              </div>
            </form>
          )}
          {!showLoginForm && showOktaButton && (
            <div className="okta-wrapper">
              <Button
                type="button"
                color="blue"
                size="big"
                onClick={this.loginWithOkta}
                className={`okta-login ${this.state.oktaLoading ? "loading" : ""}`}
                disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
              >
                Login with <img src={okta} alt="" />
              </Button>
              {this.renderTermsGuidelinesCheckbox()}
            </div>
          )}
        </div>
        <header className="caption">
          <div className="content-wrapper">
            <h1>Meiro Integrations</h1>
            <h2>Your way out of the data labyrinth.</h2>
          </div>
        </header>
      </section>
    )
  }
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
}

Login = reduxForm({
  form: "LoginForm",
  touchOnBlur: false
})(connect(null, { login, showToast })(Login))

export default Login
