import _toString from "lodash/toString"
import { createSelector } from "reselect"
import { Map, List } from "immutable"

import { initFuseSearch } from "helpers/table.helper"

export const getAreDawgWorkspacesFetching = state => state.dawgWorkspaces.get("isFetching")

export const areDawgWorkspacesFulfilled = (state, dawgId) => {
  return state.dawgWorkspaces.getIn(["data", _toString(dawgId), "isFulfilled"]) === true
}

export const getAreDawgWorkspacesModifying = (state, dawgId) => {
  return state.dawgWorkspaces.getIn(["data", _toString(dawgId), "isModifying"]) === true
}

export const getDawgWorkspacesData = (state, dawgId) => {
  if (state.dawgWorkspaces.getIn(["data", _toString(dawgId)])) {
    return state.dawgWorkspaces.getIn(["data", _toString(dawgId), "workspaces"])
  }
  return null
}

export const getDawgWorkspaceData = (state, dawgId, workspaceId) => {
  if (state.dawgWorkspaces.getIn(["data", _toString(dawgId)])) {
    return state.dawgWorkspaces.getIn([
      "data",
      _toString(dawgId),
      "workspaces",
      _toString(workspaceId)
    ])
  }
  return null
}

export const getDawgWorkspacesFuseData = createSelector([getDawgWorkspacesData], workspaces => {
  if (workspaces) {
    return Map({
      isFulfilled: true,
      data: initFuseSearch(List(workspaces.toArray()), ["name"])
    })
  } else {
    return Map({
      isFulfilled: false,
      data: initFuseSearch(List(), ["name"])
    })
  }
})
