import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _toString from "lodash/toString"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _isNil from "lodash/isNil"
import ReactTooltip from "react-tooltip"
import { Map, List, Record } from "immutable"
import TimeAgo from "react-timeago"
import moment from "moment"

// UI components-elements
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button"
import NameForm from "components/UI/components/NameForm"
import Tag from "components/UI/elements/Tag"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import AddUserAclModal from "./AddUserAclModal"
import EditUserAclModal from "./EditUserAclModal"
import Avatar from "components/UI/elements/Avatar"
import TagPicker from "components/UI/components/TagPicker"
import DeleteDataCheckbox from "components/UI/components/DeleteDataCheckbox"
import WsConfStickyNav from "components/UI/elements/WsConfStickyNav"
import DawgLink from "components/UI/elements/DawgLink"
import WorkersCount from "components/UI/components/WorkersCount"
// selectors
import { getUsersData } from "selectors/user.selector"
import { getTagsSortedByName } from "selectors/tag.selector"

// actions
import { showToast } from "actions/toast.action"

// constants
import { PERMISSION, TOAST } from "sharedConstants"

// helpers
import { getRoutePath } from "routes"
import { goBackInHistory } from "helpers/backButton.helper"
import { shortenString, capitalize } from "helpers/string.helper"

import "./DetailHeader.css"

class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      addUserAclModalOpen: false,
      editUserAclModal: {
        open: false,
        user: null,
        acl: null
      },
      allUsersShown: false
    }
  }

  openAddUserAclModal = () => {
    this.setState({
      addUserAclModalOpen: true
    })
  }

  closeAddUserAclModal = () => {
    this.setState({
      addUserAclModalOpen: false
    })
  }

  openEditUserAclModal = (acl, disabled) => () => {
    if (!disabled) {
      const { users } = this.props
      this.setState({
        editUserAclModal: {
          user: users.getIn(["data", _toString(acl.user_id)]),
          open: true,
          acl
        }
      })
    }
  }

  closeEditUserAclModal = () => {
    this.setState({
      editUserAclModal: {
        ...this.state.editUserAclModal,
        open: false
      }
    })
  }

  changeEntityName = name => {
    const { entity, entityName } = this.props
    this.props
      .handleEntityModify(
        { name },
        { name: entity.name },
        `${entityName === "dawg" ? "DAWG" : capitalize(entityName)}'s name has been modified.`,
        TOAST.TYPE.SUCCESS
      )
      .catch(_noop)
  }

  assignTag = tagId => {
    const { entity, showToast, assignTagToEntity, entityName } = this.props
    const tagIds = entity.tags.map(tag => tag.id)
    if (tagIds.findIndex(existingTagId => existingTagId === tagId) !== -1) {
      showToast(`Tag is already assigned to ${entityName}.`, TOAST.TYPE.ERROR)
    } else {
      assignTagToEntity(entity.id, { tag_id: tagId })
        .then(() => {
          showToast(`Tag has been assigned to ${entityName}`, TOAST.TYPE.SUCCESS)
        })
        .catch(_noop)
    }
  }

  unassignTag = tagId => () => {
    const { unassignTagFromEntity, entity, showToast, entityName } = this.props
    unassignTagFromEntity(entity.id, tagId)
      .then(() => {
        showToast(`Tag has been removed from ${entityName}.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
  }

  toggleEntityDisabled = () => {
    const { entity, handleEntityModify, entityName } = this.props
    handleEntityModify(
      { disabled: entity.disabled ? 0 : 1 },
      {},
      entity.disabled
        ? `${capitalize(entityName)} has been enabled.`
        : `${capitalize(entityName)} has been disabled.`
    )
  }

  toggleEntityContinueOnFailure = () => {
    const { entity, handleEntityModify } = this.props
    handleEntityModify(
      { continue_on_failure: entity.continue_on_failure ? 0 : 1 },
      {},
      entity.continue_on_failure
        ? "DAWG job will stop on workspace failure."
        : "DAWG job will continue on workspace failure."
    )
  }

  showAllUsers = () => {
    this.setState({
      allUsersShown: true
    })
  }

  renderBackButton = () => {
    const { history, entityName } = this.props
    const altBackPath =
      entityName === "workspace" ? getRoutePath("workspace.list") : getRoutePath("dawg.list")
    return (
      <Button
        onClick={goBackInHistory(history, altBackPath)}
        size="small"
        className="back-button"
        color="none"
      >
        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
      </Button>
    )
  }

  renderEntityActionButtons = placement => {
    const {
      handleEntityClone,
      handleEntityDelete,
      handleDataErase,
      isEditable,
      entity,
      entityName,
      currentlyEditing = ""
    } = this.props

    return (
      <React.Fragment>
        <Button
          type="button"
          size="small"
          color="white-red"
          onClick={handleEntityDelete}
          className="bigger-spacing"
          disabled={!isEditable}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-delete-entity-tooltip-id`}
          data-cy={`delete-entity-button-${placement}`}
        >
          <FontAwesomeIcon className="icon" icon={["far", "trash-alt"]} /> Delete
        </Button>
        {!_isNil(handleDataErase) && (
          <Button
            type="button"
            size="small"
            color="white-red"
            onClick={handleDataErase}
            disabled={!isEditable}
            currentlyEditing={currentlyEditing}
            tooltipId={`${placement}-erase-entity-data-tooltip-id`}
            data-cy={`erase-data-button-${placement}`}
          >
            <FontAwesomeIcon className="icon" icon={["far", "eraser"]} /> Erase data
          </Button>
        )}

        {!_isNil(handleEntityClone) && (
          <Button
            type="button"
            size="small"
            color="white"
            onClick={handleEntityClone}
            disabled={!isEditable}
            currentlyEditing={currentlyEditing}
            tooltipId={`${placement}-clone-entity-tooltip-id`}
            data-cy={`clone-entity-button-${placement}`}
          >
            <FontAwesomeIcon className="icon" icon={["far", "clone"]} /> Clone
          </Button>
        )}

        {entityName === "workspace" && entity.dawg && (
          <React.Fragment>
            <ToggleSwitch
              width="185px"
              name={`${placement}-continue_on_failure`}
              leftValue="1"
              rightValue="0"
              leftText="continue on failure"
              rightText="stop on errors"
              checked={_toString(entity.continue_on_failure)}
              handleToggle={this.toggleEntityContinueOnFailure}
              disabled={!isEditable}
              currentlyEditing={currentlyEditing}
              tooltipId={`${placement}-continue-on-failure-workspace-tooltip-id`}
              className={`continue-on-failure multiline ${
                entity.continue_on_failure ? "green" : "red"
              }`}
              data-cy="stop-continue-on-failure"
            />
            <div className="delimiter" />
          </React.Fragment>
        )}

        <ToggleSwitch
          width="155px"
          name={`${placement}-enabled-toggle`}
          leftValue="0"
          rightValue="1"
          leftText="Enabled"
          rightText="Disabled"
          checked={_toString(entity.disabled)}
          handleToggle={this.toggleEntityDisabled}
          disabled={!isEditable}
          currentlyEditing={currentlyEditing}
          tooltipId={`${placement}-toggle-disabled-entity-tooltip-id`}
          className={`ws-switch-margin ${entity.disabled ? "red" : "green"}`}
          data-cy="enabled-disabled-switch"
        />
      </React.Fragment>
    )
  }

  renderEntityName = () => {
    const { entity } = this.props
    const name = entity.name
    let className = ""
    if (name.length > 35) {
      className = "x-small"
    } else if (name.length > 30) {
      className = "small"
    } else if (name.length > 25) {
      className = "mid"
    }
    return <h2 className={className}>{shortenString(name, 40)}</h2>
  }

  renderRunButton = placement => {
    const {
      handleEntityRun,
      handleEntityCancel,
      entityStatus,
      isEditable,
      currentlyEditing = ""
    } = this.props
    return (
      <Button
        type="button"
        size="small"
        color={["waiting", "running"].includes(entityStatus) ? "red" : "primary"}
        onClick={
          ["waiting", "running"].includes(entityStatus) ? handleEntityCancel : handleEntityRun
        }
        disabled={!isEditable}
        currentlyEditing={currentlyEditing}
        tooltipId={`${placement}-run-entity-tooltip-id`}
        className="run-button"
      >
        {["waiting", "running"].includes(entityStatus) && (
          <React.Fragment>
            <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> Cancel
          </React.Fragment>
        )}
        {!["waiting", "running"].includes(entityStatus) && (
          <React.Fragment>
            <FontAwesomeIcon className="icon" icon={["far", "play"]} /> Run
          </React.Fragment>
        )}
      </Button>
    )
  }

  renderTagsPart = () => {
    const { entity, tagsList, isEditable, isEntityReloading, currentlyEditing = "" } = this.props
    const tagIds = entity.tags.map(tag => tag.id)
    return (
      <div className="tags">
        <p className="label">
          <FontAwesomeIcon icon={["far", "tag"]} /> Tags
        </p>
        <div className="tags-content">
          {tagIds.map(tagId => {
            const tag = tagsList.find(val => val.id === tagId)
            if (tag) {
              return (
                <Tag
                  key={tag.id}
                  clickable={isEditable && currentlyEditing.length === 0}
                  color={tag.color ? tag.color : "primary"}
                  onClick={
                    isEditable && currentlyEditing.length === 0 && !isEntityReloading
                      ? this.unassignTag(tag.id)
                      : _noop
                  }
                  className={isEntityReloading ? "wait-cursor" : ""}
                >
                  {tag.name}
                </Tag>
              )
            }
            return null
          })}
          <TagPicker
            className={isEntityReloading ? "cursor-wait" : ""}
            selectedTagIds={tagIds}
            allTags={tagsList}
            onTagSelect={this.assignTag}
            tooltipId="tag-picker-tooltip-id"
            currentlyEditing={currentlyEditing}
            disabled={!isEditable}
            readyToEdit={!isEntityReloading}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      entity,
      createAcl,
      modifyAcl,
      deleteAcl,
      fetchUsersAcl,
      entityUsers,
      entityName,
      users,
      isEditable,
      handleDataDeleteCheckboxToggle,
      handleEntityModify,
      toggleNameFormEditMode,
      nameFormEditMode,
      isEntityReloading,
      currentlyEditing = ""
    } = this.props
    const { addUserAclModalOpen, editUserAclModal, allUsersShown } = this.state

    return (
      <div className="entity-detail-header-wrapper">
        <div className="above-paper-left">{this.renderBackButton()}</div>
        <div className="above-paper-right action-buttons" data-cy="entity-action-buttons">
          <p>
            Saved{" "}
            {
              <TimeAgo
                date={moment
                  .utc(entity.created)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss")}
              />
            }
          </p>
          <Link
            className="bigger-spacing version-history"
            to={getRoutePath(
              entityName === "workspace"
                ? "workspace.workspaceHistory.list"
                : "dawg.dawgHistory.list",
              { id: entity.id }
            )}
          >
            Version history
          </Link>
          {this.renderEntityActionButtons("regular-header")}
        </div>
        <PaperHeader className={`entity-detail-header ${`${entityName}-detail-header`}`}>
          <div className="name-tags-wrapper">
            <div className="entity-name-form-wrapper">
              <NameForm
                initialValues={{ name: entity.name }}
                handleNameChange={this.changeEntityName}
                isEditable={isEditable}
                readOnlySign={!isEditable}
                toggleEditMode={toggleNameFormEditMode}
                editMode={nameFormEditMode}
                currentlyEditing={currentlyEditing}
                tooltipId="entity-name-form-tooltip-id"
                size="big"
                form={`${entityName}-name-form`}
                label={`Name your ${entityName}`}
                readyToEdit={!isEntityReloading}
              />
            </div>
            {entityName === "dawg" && this.renderTagsPart()}
          </div>
          <div className="users-run-wrapper">
            <div className="users">
              <p className="label">
                <FontAwesomeIcon icon={["far", "user"]} /> Users
              </p>
              <div className="users-content">
                <div className={`users-avatars ${allUsersShown ? "all-shown" : ""}`}>
                  {entityUsers && users.get("data").size > 0 && (
                    <React.Fragment>
                      {entityUsers.map((acl, index) => {
                        const user = users.getIn(["data", _toString(acl.user_id)])
                        if (!user) {
                          return null
                        }
                        if (!allUsersShown && index > 2) return null

                        return (
                          <div
                            className={`user-avatar user-avatar-${index} ${
                              !isEditable ? "disabled" : ""
                            }`}
                            key={acl.id}
                            data-tip
                            data-for={_toString(acl.id)}
                            onClick={this.openEditUserAclModal(acl, !isEditable)}
                          >
                            <Avatar name={user.name} email={user.email} />
                            <ReactTooltip id={_toString(acl.id)} place="bottom">
                              {acl.permission === PERMISSION.WRITE && (
                                <span>
                                  <FontAwesomeIcon icon={["fal", "pencil"]} /> {user.name}
                                </span>
                              )}
                              {acl.permission === PERMISSION.READ && (
                                <span>
                                  <FontAwesomeIcon icon={["fal", "eye"]} /> {user.name}
                                </span>
                              )}
                            </ReactTooltip>
                          </div>
                        )
                      })}
                      {entityUsers.size > 3 && !allUsersShown && (
                        <div className="user-avatar all-users-button" onClick={this.showAllUsers}>
                          <div className="avatar-wrapper">
                            <span>+{entityUsers.size - 3}</span>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <Button
                  size="small"
                  color="white"
                  onClick={this.openAddUserAclModal}
                  disabled={!isEditable}
                  tooltipId="invite-user-entity-tooltip"
                  currentlyEditing={currentlyEditing}
                  className="invite-user-button"
                  data-cy="invite-user-button"
                >
                  <FontAwesomeIcon icon={["far", "user-plus"]} />
                </Button>
              </div>
              <AddUserAclModal
                open={addUserAclModalOpen}
                handleClose={this.closeAddUserAclModal}
                createAcl={createAcl}
                entityUsers={entityUsers}
                entityName={entityName}
                entity={entity}
                users={users.get("data")}
                initialValues={{
                  "invite-users": [{ permission: PERMISSION.READ }]
                }}
              />
              <EditUserAclModal
                open={editUserAclModal.open}
                entityName={entityName}
                handleClose={this.closeEditUserAclModal}
                initialValues={{
                  permission: _get(editUserAclModal, "acl.permission", "read")
                }}
                user={editUserAclModal.user}
                acl={editUserAclModal.acl}
                fetchUsersAcl={fetchUsersAcl}
                modifyAcl={modifyAcl}
                deleteAcl={deleteAcl}
              />
            </div>
            {entityName === "workspace" && (
              <WorkersCount
                initialValue={entity.getIn(["settings", "max_workers_used"])}
                settings={entity.settings}
                modifyEntity={handleEntityModify}
              />
            )}
            <div
              className={`run-section ${
                _isNil(handleDataDeleteCheckboxToggle) ? "no-data-checkbox" : ""
              }`}
            >
              {entityName !== "workspace" && this.renderRunButton("regular-header")}
              {!_isNil(handleDataDeleteCheckboxToggle) && (
                <DeleteDataCheckbox
                  value={entity.getIn(["settings", "delete_data_after_success"], false)}
                  onChange={handleDataDeleteCheckboxToggle}
                  disabled={!isEditable}
                  tooltipId="delete-data-entity-tooltip-id"
                  currentlyEditing={currentlyEditing}
                  readyToEdit={!isEntityReloading}
                />
              )}
              {entityName === "workspace" && this.renderRunButton("regular-header")}
            </div>
          </div>
        </PaperHeader>
        {entityName === "workspace" && (
          <Paper hasHeader className="workspace-subheader">
            {this.renderTagsPart()}
            {entity.dawg && (
              <div className="dawg-part">
                <p className="label">
                  <FontAwesomeIcon icon={["fas", "chart-network"]} className="icon" />
                  Part of DAWG
                </p>
                <DawgLink
                  route={getRoutePath("dawg.show", { id: entity.dawg.id })}
                  name={entity.dawg.name}
                />
              </div>
            )}
          </Paper>
        )}
        <WsConfStickyNav>
          <div className="left-side">
            {this.renderBackButton()}
            {this.renderEntityName()}
          </div>
          <div className="right-side">
            {this.renderEntityActionButtons("sticky-header")}
            <div className="vertical-line" />
            {this.renderRunButton("sticky-header")}
          </div>
        </WsConfStickyNav>
      </div>
    )
  }
}

Header.propTypes = {
  entity: PropTypes.instanceOf(Record).isRequired,
  createAcl: PropTypes.func.isRequired,
  entityUsers: PropTypes.instanceOf(List),
  entityName: PropTypes.string.isRequired,
  fetchUsersAcl: PropTypes.func.isRequired,
  modifyAcl: PropTypes.func.isRequired,
  deleteAcl: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Map).isRequired,
  tagsList: PropTypes.instanceOf(List).isRequired,
  handleEntityClone: PropTypes.func,
  handleEntityDelete: PropTypes.func.isRequired,
  handleEntityModify: PropTypes.func.isRequired,
  handleEntityRun: PropTypes.func.isRequired,
  handleEntityCancel: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  assignTagToEntity: PropTypes.func.isRequired,
  unassignTagFromEntity: PropTypes.func.isRequired,
  handleDataErase: PropTypes.func,
  handleDataDeleteCheckboxToggle: PropTypes.func,
  entityStatus: PropTypes.string,
  currentlyEditing: PropTypes.string,
  toggleNameFormEditMode: PropTypes.func.isRequired,
  nameFormEditMode: PropTypes.bool.isRequired,
  isEntityReloading: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  users: getUsersData(state),
  tagsList: getTagsSortedByName(state)
})

export default withRouter(connect(mapStateToProps, { showToast })(Header))
