import React from "react"
import _get from "lodash/get"
import _isNil from "lodash/isNil"
import { formatBytes } from "helpers/dataUnits.helper"
import DateTimeWithTooltip from "components/UI/elements/DateTimeWithTooltip"
import {
  generateSchedulesReadableObject,
  getSetSchedulesDescriptionArray,
  computeNextSchedulesDates
} from "helpers/schedules.helper"
import { List, Map } from "immutable"
import TimeAgo from "react-timeago"
import moment from "moment"
import { Link } from "react-router-dom"
import DawgLink from "components/UI/elements/DawgLink"
import { getRoutePath } from "routes"
import StatusElement from "components/UI/elements/StatusElement"
import Tag from "components/UI/elements/Tag"
import WorkspaceLink from "components/UI/elements/WorkspaceLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ListItem.css"

export const DataProcessed = ({ job }) => {
  let content = null
  if (job === undefined || _get(job, "stats") === null) {
    content = <li>–</li>
  } else if (!_isNil(job) && !_isNil(job.stats)) {
    const inFilesCount = job.stats.getIn(["files_count", "/in"])
    const outFilesCount = job.stats.getIn(["files_count", "/out"])
    content = (
      <React.Fragment>
        <li>
          in:&nbsp;
          <strong>{formatBytes(job.stats.getIn(["data_volumes", "/in"]))}</strong>
          &nbsp;in&nbsp;
          <strong>
            {inFilesCount}&nbsp;{inFilesCount === 1 ? "file" : "files"}
          </strong>
          ,{" "}
        </li>
        <li>
          out:&nbsp;
          <strong>{formatBytes(job.stats.getIn(["data_volumes", "/out"]))}</strong>
          &nbsp;in&nbsp;
          <strong>
            {outFilesCount}&nbsp;{outFilesCount === 1 ? "file" : "files"}
          </strong>
        </li>
      </React.Fragment>
    )
  }
  return (
    <div className="data-processed">
      <span className="label">Data processed</span>
      <ul>{content}</ul>
    </div>
  )
}

export const DiskUsage = ({ value, markDiskSpaceUsed }) => {
  let content = null
  if (value === null) {
    content = "–"
  } else {
    content = (
      <span className="total-used">
        Total:{" "}
        <span className={`value ${markDiskSpaceUsed ? "marked" : ""}`}>
          {formatBytes(value)}
          {markDiskSpaceUsed && (
            <span className="icon-wrapper">
              <FontAwesomeIcon icon={["fas", "exclamation"]} />
            </span>
          )}
        </span>
      </span>
    )
  }
  return (
    <div className="disk-space-used">
      <span className="label">Disk space used</span>
      <p className="content">{content}</p>
    </div>
  )
}

export const Schedules = ({ item, expandedSchedules, expandItemSchedules }) => {
  // generateSchedulesReadableObject, getSetSchedulesDescriptionArray
  const schedules = item.getIn(["settings", "schedules"])
  const readableSchedules = generateSchedulesReadableObject(schedules)
  if (readableSchedules.size > 0) {
    const scheduleSettingDescriptionArray = getSetSchedulesDescriptionArray(readableSchedules)
    const nextSchedules = computeNextSchedulesDates(schedules)
    let scheduleSettingDescription = ""
    if (scheduleSettingDescriptionArray.size === 1) {
      scheduleSettingDescription = (
        <p className="schedule-message">{scheduleSettingDescriptionArray.get(0)}</p>
      )
    } else if (scheduleSettingDescriptionArray.size > 1 && expandedSchedules.get(item.id)) {
      scheduleSettingDescription = (
        <p className="schedule-message">
          {scheduleSettingDescriptionArray.map((entry, idx) => {
            return (
              <React.Fragment key={idx}>
                {idx > 0 && " / "}
                {entry}
              </React.Fragment>
            )
          })}
        </p>
      )
    } else if (scheduleSettingDescriptionArray.size > 1) {
      if (scheduleSettingDescriptionArray.size === 2) {
        scheduleSettingDescription = [
          <p key={1} className="schedule-message">
            {scheduleSettingDescriptionArray.get(0)} / {scheduleSettingDescriptionArray.get(1)}
          </p>
        ]
      } else {
        scheduleSettingDescription = [
          <p key={1} className="schedule-message">
            {scheduleSettingDescriptionArray.get(0)}
          </p>,
          <p key={2} className="show-more-wrapper">
            {" "}
            /{" "}
            <span className="show-more" onClick={expandItemSchedules(item.id)}>
              +{scheduleSettingDescriptionArray.size - 1} more
            </span>
          </p>
        ]
      }
    }
    return (
      <div className="set-schedule">
        <div className="set-schedule-inner">
          <div className="info">
            <span className="label">Set schedule (UTC)</span>
            <div className="schedule-desc-wrapper">{scheduleSettingDescription}</div>
          </div>
          <div className="next">
            <span className="label">Next schedules (local time)</span>
            <div className="next-schedules">
              {List.isList(nextSchedules) &&
                nextSchedules.map((schedule, idx) => (
                  <span className="schedule-item" key={idx}>
                    <DateTimeWithTooltip
                      momentDateTime={schedule}
                      uniqueTooltipId={`item-${item.id}-schedule-tooltip-${idx}`}
                    />
                  </span>
                ))}
              {!List.isList(nextSchedules) && "–"}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export const Author = ({ authorName, className }) => (
  <div className={className ? `author ${className}` : "author"}>
    <span className="label">Author</span>
    <p>{authorName}</p>
  </div>
)

export const ItemName = ({ route, name }) => {
  return (
    <div className="item-name">
      <div className="item-name-wrapper">
        <span className="label">Name</span>
        <Link to={route}>
          <h3 className="heading-hover" data-cy="item-heading">
            {name}
          </h3>
        </Link>
      </div>
    </div>
  )
}

export const LastRun = ({ datetime, labelText = "Activity" }) => (
  <div className="last-run">
    <span className="label">{labelText}</span>
    <p>
      {datetime !== null && (
        <TimeAgo
          date={moment
            .utc(datetime)
            .local()
            .format("YYYY-MM-DD HH:mm:ss")}
        />
      )}
      {datetime === null && "–"}
    </p>
  </div>
)

export const PartOfDawg = ({ dawg, maxChars = 35, className }) => {
  if (dawg) {
    return (
      <div className={className ? `dawg-name ${className}` : "dawg-name"}>
        <span className="label">Part of DAWG</span>
        <DawgLink
          route={getRoutePath("dawg.show", { id: dawg.get("id") })}
          name={dawg.get("name")}
          maxChars={maxChars}
        />
      </div>
    )
  }
  return null
}

export const Row = ({ children, className }) => (
  <div className={className ? `${className} row` : "row"}>{children}</div>
)

export const ItemStatus = ({ job, className }) => {
  return (
    <div className={className ? `${className} status` : "status"}>
      <span className="label">Status</span>
      {!_isNil(job) && (
        <Link
          to={{
            pathname: getRoutePath("workspace.workspaceJob.show", {
              id: job.workspace_id,
              aid: job.id
            }),
            state: {
              previous: true
            }
          }}
          className="status-link"
        >
          <StatusElement
            align="left"
            status={job.status}
            history={job.stats ? job.stats.get("statuses_history") : Map()}
            created={job.origin_created ? job.origin_created : job.created}
            showDuration={true}
          />
        </Link>
      )}
      {job === undefined && <span className="no-run">–</span>}
    </div>
  )
}

export const Tags = ({ tags, selectFilterTag }) => {
  const tagsSize = tags && tags.size > 0
  if (tagsSize > 0) {
    return (
      <footer className="item-tags">
        <span className="label">Tags</span>
        {tags.map(tag => {
          return (
            <Tag
              key={tag.id}
              color={tag.color ? tag.color : "primary"}
              onClick={selectFilterTag(tag.id)}
              className="entity-tag"
            >
              {tag.name}
            </Tag>
          )
        })}
      </footer>
    )
  }
  return null
}

export const DawgWorkspaces = ({ exampleWorkspaces, workspacesCount }) => {
  if (List.isList(exampleWorkspaces) && exampleWorkspaces.size > 0) {
    const anotherWsCount = workspacesCount - exampleWorkspaces.size
    return (
      <div className="example-workspaces">
        <div className="example-workspaces-inner">
          <span className="label">Workspaces</span>
          {exampleWorkspaces.map(ws => {
            return (
              <WorkspaceLink
                name={ws.name}
                route={getRoutePath("workspace.show", { id: ws.id })}
                key={ws.id}
              />
            )
          })}
          {anotherWsCount > 0 && (
            <span className="ws more">
              ... {anotherWsCount} more {anotherWsCount === 1 ? "workspace" : "workspaces"}
            </span>
          )}
        </div>
      </div>
    )
  }
  return null
}

export default ({
  isDisabled = false,
  isCompact = false,
  isInvisible = false,
  innerRef,
  job,
  children
}) => (
  <div
    className={`list-item ${isDisabled ? "disabled" : ""} ${isCompact ? "compact" : ""} ${
      isInvisible ? "invisible" : ""
    }`}
    ref={innerRef}
  >
    <div className={`color-border ${job ? job.status : "not-started"}`}>{children}</div>
  </div>
)
