import _ from "lodash"
import Fuse from "fuse.js"
import FuseImmutable from "fuse-immutable"

const toggleOrder = order => {
  switch (order) {
    case "asc":
      return "desc"
    case "desc":
      return "asc"
    default:
      return "asc"
  }
}

// wil be ommited soon, deprecated
export const initializeFuzeSearching = (data, columns) => {
  const options = {
    findAllMatches: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: columns
  }
  return new Fuse(data, options)
}

export const initFuseSearch = (data, columns) => {
  const options = {
    findAllMatches: true,
    threshold: 0.45,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: columns
  }
  return new FuseImmutable(data, options)
}

export const sortTableBy = (data, columnName, order) => {
  switch (columnName) {
    case "created":
    case "last_run":
      return _.orderBy(data, [row => new Date(row[columnName])], [order])
    default:
      return _.orderBy(data, [row => _.toLower(row[columnName])], [order])
  }
}

export const sortTableState = (prevSortState, actualSortColumn) => {
  if (prevSortState.by === actualSortColumn) {
    return {
      sort: { by: prevSortState.by, order: toggleOrder(prevSortState.order) }
    }
  } else {
    return { sort: { by: actualSortColumn, order: "asc" } }
  }
}
